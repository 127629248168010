import React from "react";
import Navbar from "../LandingPage/Navbar";
import Footer from "../LandingPage/Footer";

const Gallery = () => {
    return (
        <>
            <Navbar />

            <section id="call-to-action" className="call-to-action section">
                <div className="container" data-aos="fade-up" data-aos-delay="100">
                    <div className="row content justify-content-center align-items-center position-relative">
                        <div className="col-lg-8 mx-auto text-center">
                            <h2 className="display-4 mb-4">Image Gallery</h2>
                            <p className="mb-4">
                                Explore our curated collection of stunning visuals, thoughtfully displayed in this gallery. Each image captures a unique moment, offering a glimpse into the vibrant stories and emotions that inspire us every day.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <div className="container">
                <div className="row">
                    <div className="col-sm-6 col-md-4 mb-3">
                        <img src="/assets/img/1.jpg" alt="Image 1" className="fluid img-thumbnail gallery-img" />
                    </div>
                    <div className="col-sm-6 col-md-4 mb-3">
                        <img src="/assets/img/2.jpeg" alt="Image 2" className="fluid img-thumbnail gallery-img" />
                    </div>
                    <div className="col-sm-6 col-md-4 mb-3">
                        <img src="/assets/img/3.jpeg" alt="Image 3" className="fluid img-thumbnail gallery-img" />
                    </div>
                    <div className="col-sm-6 col-md-4 mb-3">
                        <img src="/assets/img/4.jpeg" alt="Image 4" className="fluid img-thumbnail gallery-img" />
                    </div>
                    <div className="col-sm-6 col-md-4 mb-3">
                        <img src="/assets/img/5.jpeg" alt="Image 5" className="fluid img-thumbnail gallery-img" />
                    </div>
                    <div className="col-sm-6 col-md-4 mb-3">
                        <img src="/assets/img/6.jpeg" alt="Image 6" className="fluid img-thumbnail gallery-img" />
                    </div>
                    <div className="col-sm-6 col-md-4 mb-3">
                        <img src="https://www.stemrobo.com/wp-content/uploads/2023/08/1-19.jpg" alt="Image 7" className="fluid img-thumbnail gallery-img" />
                    </div>
                    <div className="col-sm-6 col-md-4 mb-3">
                        <img src="/assets/img/8.jpeg" alt="Image 8" className="fluid img-thumbnail gallery-img" />
                    </div>
                    <div className="col-sm-6 col-md-4 mb-3">
                        <img src="https://www.stemrobo.com/wp-content/uploads/2023/08/1-37.jpeg" alt="Image 9" className="fluid img-thumbnail gallery-img" />
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
};

export default Gallery;
