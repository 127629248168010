import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Newsletter from './News'
import Navbar from './Navbar'
import Footer from './Footer'

export default function Home() {
    var [frontends, setFrontends] = useState([])
   

    return (
        <>
        <Navbar/>
        
            {/* <!-- Hero End --> */}
            <div className="container-fluid pt-5  hero-header">
        <div className="container pt-5">
        <section class="py-3 py-md-5 py-xl-8">
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-10 col-lg-8">
        <h3 class="fs-5 mb-2 text-secondary text-uppercase">About</h3>
        <h2 class="display-5 mb-4">At KalamLab, we inspire innovation, nurture curiosity, and ignite the passion for learning in young minds.</h2>
        <a href="/">
  <button type="button" class="btn btn-lg btn-primary mb-3 mb-md-4 mb-xl-5">Explore More</button>
</a>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="row gy-3 gy-md-4 gy-lg-0">
      <div class="col-12 col-lg-6">
        <div class="card bg-light p-3 m-0">
          <div class="row gy-3 gy-md-0 align-items-md-center">
    
            <div class="col-md-12">
              <div class="card-body p-0">
                <h2 class="card-title h4 mb-3">Why Choose KalamLab?</h2>
                <p class="card-text lead">With a focus on hands-on learning, we empower students to build, code, and innovate, creating solutions that can shape a better tomorrow.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6">
        <div class="card bg-light p-3 m-0">
          <div class="row gy-3 gy-md-0 align-items-md-center">
          
            <div class="col-md-12">
              <div class="card-body p-0">
                <h2 class="card-title h4 mb-3">Our Mission</h2>
                <p class="card-text lead">At KalamLab, we are dedicated to inspiring the next generation of innovators by making STEM education accessible, exciting, and impactful.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

        </div>
    </div>
    <hr></hr>
            <div className="container-fluid bg-light py-5">
                <div className="container py-5">
                    <div className="row g-5 align-items-center">
                        <div className="col-lg-5 wow fadeIn" data-wow-delay="0.1s">
                            <div className="btn btn-sm border rounded-pill text-primary px-3 mb-3">Our Core Team</div>
                            <h1 className="mb-4">Meet Our Experienced Team Members</h1>
                            {/* <p className="mb-4">Tempor erat elitr rebum at clita. Diam dolor diam ipsum et tempor sit. Aliqu diam
                                amet diam et eos labore. Clita erat ipsum et lorem et sit, sed stet no labore lorem sit. Sanctus
                                clita duo justo et tempor eirmod magna dolore erat amet</p>
                            <a className="btn btn-primary rounded-pill px-4" href="*">Read More</a> */}
                        </div>
                        <div className="col-lg-7">
                            <div className="row g-4">
                                <div className="col-md-6">
                                    <div className="row g-4">
                                        <div className="col-12 wow fadeIn" data-wow-delay="0.1s">
                                            <div className="team-item bg-white text-center rounded p-4 pt-0">
                                                 <img className="img-fluid rounded-circle p-4" src="assets/img/Kuldeepji.png" alt="*" />
                                                 <h5 className="mb-0">Mayank Verma</h5>
                                                <small>19+ years of experience in Telecommunication</small>
                                                {/* <div className="d-flex justify-content-center mt-3">
                                                    <a className="btn btn-square btn-primary m-1" href="*"><i
                                                        className="fab fa-facebook-f"></i></a>
                                                    <a className="btn btn-square btn-primary m-1" href="*"><i
                                                        className="fab fa-twitter"></i></a>
                                                    <a className="btn btn-square btn-primary m-1" href="*"><i
                                                        className="fab fa-instagram"></i></a>
                                                    <a className="btn btn-square btn-primary m-1" href="*"><i
                                                        className="fab fa-linkedin-in"></i></a>
                                                </div> */}
                                                
                                            </div>
                                        </div>
                                        <div className="col-12 wow fadeIn" data-wow-delay="0.5s">
                                            <div className="team-item bg-white text-center rounded p-4 pt-0">
                                                
                                                <img className="img-fluid rounded-circle p-4" src="assets/img/Kuldeepji.png" alt="*" />
                                                <h5 className="mb-0">Santosh Kumar Singh</h5>
                                                <small>9+ years experience in Renewal energy, VLSI design and AI</small>
                                                {/* <div className="d-flex justify-content-center mt-3">
                                                    <a className="btn btn-square btn-primary m-1" href="*"><i
                                                        className="fab fa-facebook-f"></i></a>
                                                    <a className="btn btn-square btn-primary m-1" href="*"><i
                                                        className="fab fa-twitter"></i></a>
                                                    <a className="btn btn-square btn-primary m-1" href="*"><i
                                                        className="fab fa-instagram"></i></a>
                                                    <a className="btn btn-square btn-primary m-1" href="*"><i
                                                        className="fab fa-linkedin-in"></i></a>
                                                </div> */}
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 pt-md-4">
                                    <div className="row g-4">
                                        <div className="col-12 wow fadeIn" data-wow-delay="0.3s">
                                            <div className="team-item bg-white text-center rounded p-4 pt-0">
                                                <img className="img-fluid rounded-circle p-4" src="assets/img/Kuldeepji.png" alt="*" />
                                                <h5 className="mb-0">Sandeep Malhotra</h5>
                                                <small>6+ years experience in Hardware Design, Data Science and AI</small>
                                                {/* <div className="d-flex justify-content-center mt-3">
                                                    <a className="btn btn-square btn-primary m-1" href="*"><i
                                                        className="fab fa-facebook-f"></i></a>
                                                    <a className="btn btn-square btn-primary m-1" href="*"><i
                                                        className="fab fa-twitter"></i></a>
                                                    <a className="btn btn-square btn-primary m-1" href="*"><i
                                                        className="fab fa-instagram"></i></a>
                                                    <a className="btn btn-square btn-primary m-1" href="*"><i
                                                        className="fab fa-linkedin-in"></i></a>
                                                </div> */}
                                            </div>
                                        </div>
                                        <div className="col-12 wow fadeIn" data-wow-delay="0.7s">
                                            <div className="team-item bg-white text-center rounded p-4 pt-0">
                                                <img className="img-fluid rounded-circle p-4" src="assets/img/Kuldeepji.png" alt="*" />
                                                <h5 className="mb-0">Kuldeep Singh</h5>
                                                <small>10+ years experience in Data Engineering</small>
                                                {/* <div className="d-flex justify-content-center mt-3">
                                                    <a className="btn btn-square btn-primary m-1" href="*"><i
                                                        className="fab fa-facebook-f"></i></a>
                                                    <a className="btn btn-square btn-primary m-1" href="*"><i
                                                        className="fab fa-twitter"></i></a>
                                                    <a className="btn btn-square btn-primary m-1" href="*"><i
                                                        className="fab fa-instagram"></i></a>
                                                    <a className="btn btn-square btn-primary m-1" href="*"><i
                                                        className="fab fa-linkedin-in"></i></a>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr></hr>
            <div className="container-fluid py-5">
  <div className="container">
    <div className="row g-5 align-items-center">
      <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
        <div className="about-img">
          <img className="img-fluid" src="https://png.pngtree.com/png-vector/20220719/ourmid/pngtree-working-online-at-home-png-image_5917080.png" alt="KalamLab About" />
        </div>
      </div>
      <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
        <div className="btn btn-sm border rounded-pill text-primary px-3 mb-3">About KalamLab</div>
        <h1 className="mb-4">Empowering Young Minds with Cutting-Edge STEM Education</h1>
        <p className="mb-4">At KalamLab, we are dedicated to nurturing creativity, innovation, and a passion for STEM. Our mission is to make STEM education accessible, hands-on, and exciting for students of all ages.</p>
        <p className="mb-4">We specialize in:</p>
        <div className="row g-3">
          <div className="col-sm-6">
            <ul>
              <li>Robotics Education & Workshops</li>
              <li>STEM Curriculum Development</li>
              <li>3D Printing & Prototyping</li>
              <li>Drone Programming</li>
              <li>Microcontroller-Based Projects</li>
            </ul>
          </div>
          <div className="col-sm-6">
            <ul>
              <li>IoT & Smart Technology</li>
              <li>AI-Powered Learning Modules</li>
              <li>ATL Lab Setup & Training</li>
              <li>Virtual Reality & AR Experiences</li>
              <li>Creative Coding for Kids</li>
            </ul>
          </div>
          <div className="col-sm-6">
            <h6 className="mb-3"><i className="fa fa-check text-primary me-2"></i>Hands-On Learning</h6>
            <h6 className="mb-0"><i className="fa fa-check text-primary me-2"></i>Experienced Educators</h6>
          </div>
          <div className="col-sm-6">
            <h6 className="mb-3"><i className="fa fa-check text-primary me-2"></i>Future-Ready Skills</h6>
            <h6 className="mb-0"><i className="fa fa-check text-primary me-2"></i>Affordable Programs</h6>
          </div>
        </div>
        <div className="d-flex align-items-center mt-4">
          <a className="btn btn-outline-primary btn-square me-3" href="mailto:contact@kalamlab.com" target="_blank" rel="noreferrer">
            <i className="fab fa-google"></i>
          </a>
          <a className="btn btn-outline-primary btn-square me-3" href="#"><i className="fab fa-twitter"></i></a>
          <a className="btn btn-outline-primary btn-square me-3" href="#"><i className="fab fa-instagram"></i></a>
          <a className="btn btn-outline-primary btn-square" href="#"><i className="fab fa-linkedin-in"></i></a>
        </div>
      </div>
    </div>
  </div>
</div>
<hr></hr>
<section id="about" class="about section m-8 ">
        <div class="container p-4">
          <div class="row gy-4 align-items-center justify-content-between">
            <div class="col-xl-12">
              <h6 class="text-primary text-center mt-4">THE SCHOOL OF KALAM LABS</h6>
              <h2 class="about-title text-center"> We guide young minds to prepare and excel in robotics competitions</h2>
              <p class="about-description mt-4 text-center">
                At The School of Kalam Labs, we guide young minds to prepare and excel in robotics competitions by offering hands-on learning, mentorship, and a supportive community. <br />Our mission is to nurture problem-solving skills, teamwork, and technical expertise among students.
              </p>
              <div class="row feature-list-wrapper">
                <div class="col-md-6">
                  <ul class="feature-list">
                    <li><i class="bi bi-check-circle-fill"></i> World Robot Olympiad (WRO) India: Categories like RoboMission, Future Innovators, and more</li>
                    <li><i class="bi bi-check-circle-fill"></i> Indian Robotics Olympiad (IRO): Focus on programming and problem-solving skills</li>
                    <li><i class="bi bi-check-circle-fill"></i> Technoxian – World Robotics Championship: Events like Bots Combat, Robo Race, and Drone Racing</li>
                    <li><i class="bi bi-check-circle-fill"></i> e-Yantra Robotics Competition (eYRC): Learn embedded systems and robotics through challenges</li>
                    <li><i class="bi bi-check-circle-fill"></i> FIRST LEGO League India: Hands-on STEM experiences for young innovators</li>
                  </ul>
                </div>
                <div class="col-md-6">
                  <ul class="feature-list">
                    <li><i class="bi bi-check-circle-fill"></i> Robotex India: Inspiring students in robotics and technology through competitions</li>
                    <li><i class="bi bi-check-circle-fill"></i> Robofest India: Autonomous robotics competitions for all age groups</li>
                    <li><i class="bi bi-check-circle-fill"></i> Techfest at IIT Bombay: Includes Robowars and International Robotics Challenges</li>
                    <li><i class="bi bi-check-circle-fill"></i> Robotix at IIT Kharagpur: Challenges in manual and autonomous robotics</li>
                    <li><i class="bi bi-check-circle-fill"></i> OMOTEC Competitions: Mentoring students for national and international contests</li>
                  </ul>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>




      <section id="call-to-action-2" className="call-to-action-2 section dark-background">
        <div className="container">
          <div className="row justify-content-center" data-aos="zoom-in" data-aos-delay="100">
            <div className="col-xl-10">
              <div className="text-center">
                <h3>Empower Young Minds with THE SCHOOL OF KALAM LAB</h3>
                <p>
                  Join the movement to foster innovation and creativity in the next generation.
                  THE SCHOOL OF KALAM LAB equips students with essential skills in robotics, AI, and STEM fields,
                  preparing them for a bright future in technology and beyond.
                </p>
                <p>Contact Us to Learn More.</p>
                <a className="cta-btn" href="/Courses1">
                  Our Products
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>


            <Newsletter />
            <Footer/>
        </>
    )
}
