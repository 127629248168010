import React, { useState, useEffect } from "react";
import Navbar from "../LandingPage/Navbar";
import Footer from "../LandingPage/Footer";
import axios from "axios";

const StudentEnrollForm = () => {
  const [formData, setFormData] = useState({
    email: '',
    username: '',
    password: '',
    firstName: '',
    lastName: '',
    dob: '',
    contactNo: '',
    emergencyContactNo: '',
    street: '',
    city: '',
    state: '',
    postalCode: '',
    country: '',
    course: '',
    batch: '',
    studentClass: '',
    guardianName: '',
    relationship: '',
    guardianContactNo: '',
    enrolledAs: "Student"
  });

  const [step, setStep] = useState(1);
  const [courses, setCourses] = useState([]);
  const [success, setSuccess] = useState(false);
  const [batches, setBatches] = useState([]); 
  const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_API_URL });
  const nextStep = () => setStep((prevStep) => prevStep + 1);
  const prevStep = () => setStep((prevStep) => prevStep - 1);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  useEffect(() => {
    let componentMounted = true;

    const getCourses = async () => {
      try {
        const response = await axiosInstance.post('/data-courses');
        if (componentMounted) {
          setCourses(response.data.data); 
        }
      } catch (error) {
        console.error('Error fetching courses:', error);
      }
    };

    const getBatches = async () => {
      try {
        const response = await axiosInstance.get('/batch/details'); 
    
          setBatches(response.data);
        
      } catch (error) {
        console.error('Error fetching batches:', error);
      }
    };

    getCourses();
    getBatches();
    return () => {
      componentMounted = false;
    };
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!formData.guardianName || !formData.relationship || !formData.guardianContactNo) {
      alert("Please fill all required fields.");
      return;
    }
    try {
      const response = await axiosInstance.post("/users/enroll", formData);
      console.log('Form submitted successfully:', response.data);
      setStep(6);
    } catch (error) {
      console.error('Error submitting form:', error);
      alert("There was an error submitting the form. Please try again.");
    }
  };


  return (
    <>
      <Navbar /><div className="container-fluid mt-4">
        <div className="row justify-content-center">
          <div className="col-11 col-sm-9 col-md-7 col-lg-6 col-xl-5 text-center p-0 mt-3 mb-2">
            <div className="card px-4 pt-4 pb-0 mt-3 mb-3">
              <h2 id="heading">Enroll in Our Virtual School</h2>
              <p>Fill in all the form fields to complete your enrollment process.</p>

              <form id="msform" onSubmit={handleSubmit}>
                {success && (
                  <div className="alert alert-success text-center" role="alert">
                    Enrollment successful! Welcome to our Virtual School.
                  </div>
                )}

                <ul id="progressbar">
                  <li className={step >= 1 ? "active" : ""} id="account">
                    <strong>Account</strong>
                  </li>
                  <li className={step >= 2 ? "active" : ""} id="personal">
                    <strong>Personal Info</strong>
                  </li>
                  <li className={step >= 3 ? "active" : ""} id="personal">
                    <strong>Address</strong>
                  </li>
                  <li className={step >= 4 ? "active" : ""} id="personal">
                    <strong>Courses</strong>
                  </li>
                  <li className={step >= 5 ? "active" : ""} id="personal">
                    <strong>Guardian Info</strong>
                  </li>
                  <li className={step === 6 ? "active" : ""} id="confirm">
                    <strong>Finish</strong>
                  </li>
                </ul>
                <div className="progress">
                  <div
                    className="progress-bar progress-bar-striped progress-bar-animated"
                    role="progressbar"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{ width: `${(step / 6) * 100}%` }}
                  ></div>
                </div>
                <br />

                {step === 1 && (
                  <fieldset>
                    <div className="form-card">
                      <div className="row">
                        <div className="col-7">
                          <h2 className="fs-title">Account Information:</h2>
                        </div>
                        <div className="col-5">
                          <h2 className="steps">Step 1 - 6</h2>
                        </div>
                      </div>
                      <label className="fieldlabels">Email: *</label>
                      <input
                        type="email"
                        name="email"
                        placeholder="Email Id"
                        value={formData.email}
                        onChange={handleChange}
                        className="form-control"
                        required
                      />
                      <label className="fieldlabels">Username: *</label>
                      <input
                        type="text"
                        name="username"
                        placeholder="UserName"
                        value={formData.username}
                        onChange={handleChange}
                        className="form-control"
                        required
                      />
                      <label className="fieldlabels">Password: *</label>
                      <input
                        type="password"
                        name="password"
                        placeholder="Password"
                        value={formData.password}
                        onChange={handleChange}
                        className="form-control"
                        required
                      />
                      <label className="fieldlabels">Confirm Password: *</label>
                      <input
                        type="password"
                        name="confirmPassword"
                        placeholder="Confirm Password"
                        value={formData.confirmPassword}
                        onChange={handleChange}
                        className="form-control"
                        required
                      />
                    </div>
                    <input
                      type="button"
                      name="next"
                      className="next action-button form-control"
                      value="Next"
                      onClick={nextStep}

                    />
                  </fieldset>
                )}

                {/* Step 2 - Personal Information */}
                {step === 2 && (
                  <fieldset>
                    <div className="form-card">
                      <div className="row">
                        <div className="col-7">
                          <h2 className="fs-title">Personal Information:</h2>
                        </div>
                        <div className="col-5">
                          <h2 className="steps">Step 2 - 6</h2>
                        </div>
                      </div>
                      <label className="fieldlabels">First Name: *</label>
                      <input
                        type="text"
                        name="firstName"
                        placeholder="First Name"
                        value={formData.firstName}
                        onChange={handleChange}
                        required
                      />
                      <label className="fieldlabels">Last Name: *</label>
                      <input
                        type="text"
                        name="lastName"
                        placeholder="Last Name"
                        value={formData.lastName}
                        onChange={handleChange}
                        required
                      />
                      <label className="fieldlabels">Date of Birth: *</label>
                      <input
                        type="date"
                        name="dob"
                        value={formData.dob}
                        onChange={handleChange}
                        required
                      />
                      <label className="fieldlabels">Contact No.: *</label>
                      <input
                        type="tel"
                        name="contactNo"
                        placeholder="Contact No."
                        value={formData.contactNo}
                        onChange={handleChange}
                        required
                      />
                      <label className="fieldlabels">Emergency Contact No.: *</label>
                      <input
                        type="tel"
                        name="emergencyContactNo"
                        placeholder="Emergency Contact No."
                        value={formData.emergencyContactNo}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <input
                      type="button"
                      name="next"
                      className="next action-button"
                      value="Next"
                      onClick={nextStep}
                    />
                    <input
                      type="button"
                      name="previous"
                      className="previous action-button-previous"
                      value="Previous"
                      onClick={prevStep}
                    />
                  </fieldset>
                )}

                {/* Step 3 - Address Information */}
                {step === 3 && (
                  <fieldset>
                    <div className="form-card">
                      <div className="row">
                        <div className="col-7">
                          <h2 className="fs-title">Address Information:</h2>
                        </div>
                        <div className="col-5">
                          <h2 className="steps">Step 3 - 6</h2>
                        </div>
                      </div>
                      <label className="fieldlabels">Street Address: *</label>
                      <input
                        type="text"
                        name="street"
                        placeholder="Street Address"
                        value={formData.street}
                        onChange={handleChange}
                        required
                      />
                      <label className="fieldlabels">City: *</label>
                      <input
                        type="text"
                        name="city"
                        placeholder="City"
                        value={formData.city}
                        onChange={handleChange}
                        required
                      />
                      <label className="fieldlabels">State: *</label>
                      <input
                        type="text"
                        name="state"
                        placeholder="State"
                        value={formData.state}
                        onChange={handleChange}
                        required
                      />
                      <label className="fieldlabels">Postal Code: *</label>
                      <input
                        type="text"
                        name="postalCode"
                        placeholder="Postal Code"
                        value={formData.postalCode}
                        onChange={handleChange}
                        required
                      />
                      <label className="fieldlabels">Country: *</label>
                      <input
                        type="text"
                        name="country"
                        placeholder="Country"
                        value={formData.country}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <input
                      type="button"
                      name="next"
                      className="next action-button"
                      value="Next"
                      onClick={nextStep}
                    />
                    <input
                      type="button"
                      name="previous"
                      className="previous action-button-previous"
                      value="Previous"
                      onClick={prevStep}
                    />
                  </fieldset>
                )}


                {/* Step 4 - Course Selection */}
                {step === 4 && (
                  <fieldset>
                    <div className="form-card">
                      <div className="row">
                        <div className="col-7">
                          <h2 className="fs-title">Course Selection:</h2>
                        </div>
                        <div className="col-5">
                          <h2 className="steps">Step 4 - 6</h2>
                        </div>
                      </div>

                      <label className="fieldlabels">Select Your Course: </label>
                      <select
                        name="course"
                        value={formData.course}
                        onChange={handleChange}
                        required
                        className="form-select custom-dropdown form-control"
                      >
                        <option value="" disabled>Choose a Course</option>
                        {courses.map((course) => (
                <option key={course._id} value={course.courseName}>
                  {course.courseName}
                </option>
              ))}
                      </select>
                      <label className="fieldlabels">Select Available Batch: </label>
                      <select
                        name="batch"
                        value={formData.batch}
                        onChange={handleChange}
                        required
                        className="form-select custom-dropdown"
                      >
                        <option value="" disabled>Choose a Batch</option>
                        {batches.map((batch) => (
                        <option key={batch._id} value={batch.date}><span className="text-inverse">
                        {new Date(batch.date).toLocaleDateString('en-US', {
                          weekday: 'long', // "Monday"
                          year: 'numeric', // "2025"
                          month: 'long', // "January"
                          day: 'numeric' // "8"
                        })}
                      </span></option>
                      ))}
                      </select>

                      <label className="fieldlabels">Select Your Class (5-12): </label>
                      <select
                        name="studentClass"
                        value={formData.studentClass}
                        onChange={handleChange}
                        required
                        className="form-select custom-dropdown"
                      >
                        <option value="" disabled>Choose a Class</option>
                        <option value="5">Class 5</option>
                        <option value="6">Class 6</option>
                        <option value="7">Class 7</option>
                        <option value="8">Class 8</option>
                        <option value="9">Class 9</option>
                        <option value="10">Class 10</option>
                        <option value="11">Class 11</option>
                        <option value="12">Class 12</option>
                      </select>

                      <div className="button-container">
                        <input
                          type="button"
                          name="next"
                          className="next action-button"
                          value="Next"
                          onClick={nextStep}
                        />
                        <input
                          type="button"
                          name="previous"
                           className="previous action-button-previous"
                          value="Previous"
                          onClick={prevStep}
                        />
                      </div>
                    </div>
                  </fieldset>
                )}


                {step === 5 && (
                  <fieldset>
                    <div className="form-card">
                      <div className="row">
                        <div className="col-7">
                          <h2 className="fs-title">Guardian Information:</h2>
                        </div>
                        <div className="col-5">
                          <h2 className="steps">Step 5 - 6</h2>
                        </div>
                      </div>
                      {/* Wrap fields in a form */}

                      <label className="fieldlabels">Guardian Name: *</label>
                      <input
                        type="text"
                        name="guardianName"
                        placeholder="Guardian's Full Name"
                        value={formData.guardianName}
                        onChange={handleChange}
                        required
                      />
                      <label className="fieldlabels">Relationship to Student: *</label>
                      <input
                        type="text"
                        name="relationship"
                        placeholder="Relationship to Student"
                        value={formData.relationship}
                        onChange={handleChange}
                        required
                      />
                      <label className="fieldlabels">Guardian Contact No.: *</label>
                      <input
                        type="tel"
                        name="guardianContactNo"
                        placeholder="Guardian's Contact No."
                        value={formData.guardianContactNo}
                        onChange={handleChange}
                        required
                      />

                      {/* Submit button */}
                      <button type="submit" className="next action-button px-3 py-2" >
                        Submit
                      </button>

                      {/* Previous button */}
                      <input
                        type="button"
                        name="previous"
                        className="previous action-button-previous"
                        value="Previous"
                        onClick={prevStep}
                      />

                    </div>
                  </fieldset>
                )}


                {step === 6 && (
                  <fieldset>
                    <div className="form-card">
                      <h2 className="purple-text text-center">
                        <strong>SUCCESS! You Have Enrolled Successfully!</strong>
                      </h2>
                      <br />
                      <h5 className="purple-text text-center">
                        Welcome to Our Virtual School!
                      </h5>
                      <div className="row justify-content-center">
                        <div className="col-3">
                          <img
                            src="https://i.imgur.com/GwStPmg.png"
                            className="fit-image"
                            alt="Success"
                          />
                        </div>
                      </div>
                    </div>
                  </fieldset>
                )}

              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default StudentEnrollForm;
