import React, { useState, useEffect } from "react";
import Sidebar from "./SideBar";
import axios from "axios";

const ScheduleClass = () => {
    const [courses, setCourses] = useState([]); // List of courses
    const [selectedCourseId, setSelectedCourseId] = useState(""); // Selected course ID
    const [selectedCourseTitle, setSelectedCourseTitle] = useState(""); // Selected course title
    const [title, settitle] = useState(""); // Class title
    const [scheduledTime, setScheduledTime] = useState(""); // Scheduled time
    const [generatedLink, setGeneratedLink] = useState(""); // Meeting link
    const [loading, setLoading] = useState(false);
    const [toast, setToast] = useState(false);

    const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_API_URL });

    // Fetch Courses from API
    useEffect(() => {
        setLoading(true);
        axiosInstance
            .get(`/get-data-courses`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("auth_token"),
                },
            })
            .then((response) => {
                setCourses(response.data.courses);
                setLoading(false);
            })
            .catch((error) => {
                console.error("Error fetching courses:", error);
                setLoading(false);
            });
    }, []);

    console.log(courses)
    // Handle Course Selection
    const handleCourseSelect = (courseId, courseTitle) => {
        setSelectedCourseId(courseId);
        setSelectedCourseTitle(courseTitle);
    };

    // Handle Form Submission to Schedule a Class
    const handleScheduleClass = (e) => {
        e.preventDefault();
        if (!selectedCourseId) {
            alert("Please select a course first.");
            return;
        }

        setLoading(true);
        axiosInstance
            .post(
                `/courses/${selectedCourseId}/schedule-class`,
                {
                    title,
                    scheduledTime,
                    link: generatedLink, // Generate unique link if not provided
                },
                {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("auth_token"),
                    },
                }
            )
          
            .then((response) => {
                setToast(true);
                settitle("");
                setScheduledTime("");
                setGeneratedLink("");
                setTimeout(() => setToast(false), 3000);
                setLoading(false);
            })
            .catch((error) => {
                console.error("Error scheduling class:", error);
                setLoading(false);
            });
    };

    return (
        <div>
            <div className="app-container app-theme-white body-tabs-shadow fixed-sidebar fixed-header" id="appContent">
                <div className="app-main">
                    <Sidebar />
                    <div className="col mt-4">
                        <div className="row">
                            <div className="page-title-actions px-3 d-flex">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a href="">Dashboard</a></li>
                                        <li className="breadcrumb-item"><a href="">Courses</a></li>
                                        <li className="breadcrumb-item active" aria-current="page">Schedule Class</li>
                                    </ol>
                                </nav>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="main-card card d-flex h-100 flex-column">
                                        <div className="card-body">
                                            <h5 className="card-title py-2">Select a Course</h5>
                                            <div className="mb-3">
    {loading && <p>Loading courses...</p>}
    {!loading && courses.length === 0 && <p>No courses available.</p>}
    <div className="row">
        {courses.map((course) => (
            <div className="col-md-4 mb-3" key={course._id}>
                <div
                    className={`card ${selectedCourseId === course._id ? "border-primary" : ""}`}
                    style={{ cursor: "pointer" }}
                    onClick={() => handleCourseSelect(course._id, course.courseName)}
                >
                    <div className="card-body text-center">
                        <h5 className="card-title">{course.courseName}</h5>
                     
                        <button
                            className={`btn mt-4 ${selectedCourseId === course._id ? "btn-primary" : "btn-outline-primary"}`}
                        >
                            {selectedCourseId === course._id ? "Selected" : "Select Course"}
                        </button>
                    </div>
                </div>
            </div>
        ))}
    </div>
</div>

                                            {selectedCourseId && (
                                                <>
                                                    <h5 className="card-title py-2">Schedule a Class for "{selectedCourseTitle}"</h5>
                                                    <form onSubmit={handleScheduleClass}>
                                                        <div className="mb-3">
                                                            <label className="form-label">Class Title</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                value={title}
                                                                onChange={(e) => settitle(e.target.value)}
                                                                required
                                                            />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label">Scheduled Time</label>
                                                            <input
                                                                type="datetime-local"
                                                                className="form-control"
                                                                value={scheduledTime}
                                                                onChange={(e) => setScheduledTime(e.target.value)}
                                                                required
                                                            />
                                                        </div>
                                                     
                                                        <button type="submit" className="btn btn-primary">Schedule Class</button>
                                                    </form>
                                                    {toast && <p className="text-success mt-3">Class scheduled successfully!</p>}
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ScheduleClass;
