import React, { useState } from "react";
import Navbar from "../LandingPage/Navbar";
import Footer from "../LandingPage/Footer";

const LearnMoreBatches = () => {
  
  
  return(
        <div> 
        <Navbar/>
         <main class="main">
         <section id="hero" className="hero section light-background">
        <img
          src="https://img.freepik.com/premium-photo/happy-diverse-kids-celebrating-back-school-with-backpacks-confetti_494260-1320.jpg"
          alt="Virtual School"
          data-aos="fade-in"
        />
        <div className="container position-relative">
          <div className="content row gy-4 mt-4 py-4">
            <div className="col-lg-4 d-flex align-items-stretch py-4">
              <div className="why-box" data-aos="zoom-out" data-aos-delay="200">
                <h3>Why Join Our Virtual School?</h3>
                <p className="text-white">
                  Our virtual school offers a wide range of interactive courses and
                  batches designed to meet diverse learning needs. From beginner
                  programming to advanced AI and robotics, we ensure a flexible and
                  personalized online learning experience.
                </p>
              </div>
            </div>

            <div className="col-lg-8 d-flex align-items-stretch">
              <div className="d-flex flex-column justify-content-center">
                <div className="row gy-4">
                  <div className="col-xl-4 d-flex align-items-stretch">
                    <div className="icon-box" data-aos="zoom-out" data-aos-delay="300">
                      <i className="bi bi-gear"></i>
                      <h4>Flexible Batches</h4>
                      <p>
                        Choose from various batches tailored to suit your schedule
                        and learning pace.
                      </p>
                    </div>
                  </div>

                  <div className="col-xl-4 d-flex align-items-stretch">
                    <div className="icon-box" data-aos="zoom-out" data-aos-delay="400">
                      <i className="bi bi-code-slash"></i>
                      <h4>Diverse Courses</h4>
                      <p>
                        Explore courses ranging from AI and robotics to web
                        development and IoT.
                      </p>
                    </div>
                  </div>

                  <div className="col-xl-4 d-flex align-items-stretch">
                    <div className="icon-box" data-aos="zoom-out" data-aos-delay="500">
                      <i className="bi bi-lightbulb"></i>
                      <h4>Interactive Learning</h4>
                      <p>
                        Participate in live classes and collaborative projects for
                        hands-on learning.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* About Section */}
      <section id="about" className="about section m-8">
        <div className="container p-4 mt-4" data-aos="fade-up" data-aos-delay="100">
          <div className="row gy-4 align-items-center justify-content-between">
            <div className="col-xl-5 mt-4" data-aos="fade-up" data-aos-delay="200">
              <span className="about-meta">ABOUT US</span>
              <h2 className="about-title">Your Gateway to Future-Ready Learning</h2>
              <p className="about-description">
                Our virtual school offers curated courses and batches designed for
                students of all levels. With expert instructors and real-world
                projects, we empower learners to excel in a technology-driven world.
              </p>
              <div className="row feature-list-wrapper">
                <div className="col-md-6">
                  <ul className="feature-list">
                    <li><i className="bi bi-check-circle-fill"></i> Multiple Batch Timings</li>
                    <li><i className="bi bi-check-circle-fill"></i> Beginner to Advanced Levels</li>
                    <li><i className="bi bi-check-circle-fill"></i> Hands-On Projects</li>
                  </ul>
                </div>
                <div className="col-md-6">
                  <ul className="feature-list">
                    <li><i className="bi bi-check-circle-fill"></i> Industry-Ready Skills</li>
                    <li><i className="bi bi-check-circle-fill"></i> Expert Mentors</li>
                  </ul>
                </div>
              </div>
              <div className="info-wrapper">
                <div className="row gy-4">
                  <div className="col-lg-7">
                    <div className="contact-info d-flex align-items-center gap-2">
                      <i className="bi bi-telephone-fill"></i>
                      <div>
                        <p className="contact-label">Contact Us</p>
                        <p className="contact-number">+91-8810316395</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6" data-aos="fade-up" data-aos-delay="300">
              <div className="image-wrapper">
                <div className="images position-relative" data-aos="zoom-out" data-aos-delay="400">
                  <img
                    src="https://plus.unsplash.com/premium_photo-1663106423058-c5242333348c?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8a2lkcyUyMGxlYXJuaW5nfGVufDB8fDB8fHww"
                    className="img-fluid main-image rounded-4"
                  />
                  <img
                    src="https://www.connectionsacademy.com/content/dam/pvs/ca/resource-hub/headers/RH-Header-Images-05-Trivia-for-Kids-042023.jpg"
                    className="img-fluid small-image rounded-4"
                  />
                </div>
                <div className="experience-badge floating">
                  <h3>10+ <span>Years</span></h3>
                  <p className="text-white">Experience in transforming education</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

  
  <section class="bg-light py-5 py-xl-8">
  <div class="container">
    <div class="row justify-content-md-center">
      <div class="col-12 col-md-10 col-lg-8 col-xl-7">
        <h3 class="fs-6 mb-2 text-secondary text-center text-uppercase">Our Expertise</h3>
        <h2 class="display-5 mb-5 text-center">We excel in providing top-notch skills for your success.</h2>
        <hr class="w-50 mx-auto mb-5 mb-xl-9 border-dark-subtle"/>
      </div>
    </div>
  </div>

  <div class="container overflow-hidden">
    <div class="row gy-4 gy-xl-0">
  
      <div class="col-12 col-sm-6 col-xl-3">
        <div class="card border-0 border-bottom border-primary shadow-sm">
          <div class="card-body text-center p-4 p-xxl-5">
            <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" fill="currentColor" class="bi bi-code text-primary mb-4" viewBox="0 0 16 16">
              <path d="M5.719 3.858a.5.5 0 0 0-.638-.058L.58 7.42a.5.5 0 0 0 0 .758l4.5 3.5a.5.5 0 1 0 .638-.766L1.545 8 5.719 4.624a.5.5 0 0 0 .058-.766ZM10.281 3.858a.5.5 0 0 1 .638-.058l4.5 3.5a.5.5 0 0 1 0 .758l-4.5 3.5a.5.5 0 1 1-.638-.766L14.455 8 10.281 4.624a.5.5 0 0 1-.058-.766Z" />
              <path d="M6.854 11.146a.5.5 0 0 1 0 .708l-1 1a.5.5 0 0 1-.708-.708l1-1a.5.5 0 0 1 .708 0ZM9.146 4.854a.5.5 0 0 1 0-.708l1-1a.5.5 0 1 1 .708.708l-1 1a.5.5 0 0 1-.708 0Z" />
            </svg>
            <h4 class="mb-4">Programming</h4>
            <p class="mb-4 text-secondary">Expertise in modern programming languages like JavaScript, Python, and Java to build innovative solutions.</p>
          </div>
        </div>
      </div>

      <div class="col-12 col-sm-6 col-xl-3">
        <div class="card border-0 border-bottom border-primary shadow-sm">
          <div class="card-body text-center p-4 p-xxl-5">
            <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" fill="currentColor" class="bi bi-bar-chart text-primary mb-4" viewBox="0 0 16 16">
              <path d="M0 0h1v15h15v1H0V0Zm10 5h2v8h-2V5ZM6 8h2v5H6V8ZM2 11h2v2H2v-2Z" />
            </svg>
            <h4 class="mb-4">Critical Thinking</h4>
            <p class="mb-4 text-secondary">Develop expertise in tackling multifaceted difficult problems with structured IoT-based solutions.</p>
          </div>
        </div>
      </div>

      <div class="col-12 col-sm-6 col-xl-3">
        <div class="card border-0 border-bottom border-primary shadow-sm">
          <div class="card-body text-center p-4 p-xxl-5">
            <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" fill="currentColor" class="bi bi-brush text-primary mb-4" viewBox="0 0 16 16">
              <path d="M15.825.14c-.646-.646-2.313.38-4.1 2.168-.272.271-.516.539-.733.802-1.62-1.273-3.553-1.504-4.91-.148-1.19 1.19-.97 3.219.242 4.968L.563 12.512a.25.25 0 0 0-.045.253c.302.756.548 1.374.677 1.673.143.33.347.56.58.663.244.107.521.101.92-.013.308-.09.654-.229 1.024-.403a.255.255 0 0 0 .09-.378L7.392 7.6c1.75 1.21 3.777 1.432 4.969.241 1.356-1.356 1.125-3.289-.148-4.91.263-.217.53-.46.802-.732 1.787-1.788 2.813-3.454 2.168-4.1ZM2.507 13.934c-.244.084-.468.152-.664.204.109-.196.2-.42.285-.657l.037-.1c.049-.133.097-.265.145-.388l.197.197c-.46.248-.592.324-.637.344ZM1.05 15h-.03.03Zm.005-.001.016.001H1.05Zm.022.002h-.023.023Z" />
            </svg>
            <h4 class="mb-4">Creative Design</h4>
            <p class="mb-4 text-secondary">Delivering stunning and user-centric designs for web, mobile, and print media that leave a lasting impression.</p>
          </div>
        </div>
      </div>
    
      <div class="col-12 col-sm-6 col-xl-3">
        <div class="card border-0 border-bottom border-primary shadow-sm">
          <div class="card-body text-center p-4 p-xxl-5">
            <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" fill="currentColor" class="bi bi-shield-lock text-primary mb-4" viewBox="0 0 16 16">
              <path d="M5.072 11.293a.5.5 0 0 0 .708-.707 3 3 0 1 1 4.24 0 .5.5 0 1 0 .708.707 4 4 0 1 0-5.656 0ZM8 4a2 2 0 0 1 1.716 3.008A2.99 2.99 0 0 1 8 6a2.99 2.99 0 0 1-1.716.992A2 2 0 0 1 8 4Z" />
              <path d="M8 0c-.69 0-1.382.04-2.073.115C4.224.26 3.443.525 2.854.9a4.001 4.001 0 0 0-1.878 3.053C.497 5.98 0 8.013 0 10.124c0 4.124 4.167 5.907 8 5.907s8-1.783 8-5.907c0-2.11-.497-4.144-1.975-6.171A4.001 4.001 0 0 0 13.146.9c-.59-.375-1.37-.64-2.073-.785A25.362 25.362 0 0 0 8 0Zm.52 11.743a3.522 3.522 0 0 1-1.04 0 3.496 3.496 0 0 1-.52-6.925V2.757a5.977 5.977 0 0 1 1.56 0v2.061a3.497 3.497 0 0 1-.52 6.925Z" />
            </svg>
            <h4 class="mb-4">Cybersecurity</h4>
            <p class="mb-4 text-secondary">Ensuring secure systems by implementing best practices in cybersecurity and vulnerability management.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
  
  
      <section id="call-to-action-2" className="call-to-action-2 section dark-background">
    <div className="container">
      <div className="row justify-content-center" data-aos="zoom-in" data-aos-delay="100">
        <div className="col-xl-10">
          <div className="text-center">
            <h3>Empower Young Minds with KalamLab</h3>
            <p>
              Join the movement to foster innovation and creativity in the next generation. 
              KalamLab equips students with essential skills in robotics, AI, and STEM fields, 
              preparing them for a bright future in technology and beyond.
            </p>
            <a className="cta-btn" href="/roboticsDetails">
              Our Products
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>


  <section className="bg-light py-3 py-md-5">
      <div className="container">
        <div className="row gy-5 gy-lg-0 align-items-lg-center">
          <div className="col-12 col-lg-6">
            <img
              className="img-fluid rounded"
              loading="lazy"
              src="https://bootstrapbrain.com/demo/components/faqs/faq-1/assets/img/faq-img-1.png"
              alt="Batches - Learn More"
            />
          </div>
          <div className="col-12 col-lg-6">
            <div className="row justify-content-xl-end">
              <div className="col-12 col-xl-11">
                <h2 className="h1 mb-3">Learn More About Our Batches</h2>
                <p className="lead fs-4 text-secondary mb-5">
                  Explore everything you need to know about enrolling in and
                  managing batches for our courses. For more detailed
                  information contact us directly.
                </p>
                <div className="accordion accordion-flush" id="faqAccordion">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="faqHeadingOne">
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faqCollapseOne"
                        aria-expanded="true"
                        aria-controls="faqCollapseOne"
                      >
                        How Do I Find Available Batches?
                      </button>
                    </h2>
                    <div
                      id="faqCollapseOne"
                      className="accordion-collapse collapse show"
                      aria-labelledby="faqHeadingOne"
                      data-bs-parent="#faqAccordion"
                    >
                      <div className="accordion-body px-4">
                        <p>To find available batches for your desired course:</p>
                        <ul>
                          <li>Go to the "Courses" section on our website.</li>
                          <li>Select the course you're interested in.</li>
                          <li>View the list of batches with their schedules and availability.</li>
                          <li>Click "Learn More" for detailed batch information.</li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="faqHeadingTwo">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faqCollapseTwo"
                        aria-expanded="false"
                        aria-controls="faqCollapseTwo"
                      >
                        How Do I Enroll in a Batch?
                      </button>
                    </h2>
                    <div
                      id="faqCollapseTwo"
                      className="accordion-collapse collapse"
                      aria-labelledby="faqHeadingTwo"
                      data-bs-parent="#faqAccordion"
                    >
                      <div className="accordion-body px-4">
                        <p>To enroll in a batch, follow these steps:</p>
                        <ul>
                          <li>Log in to your account or create one.</li>
                          <li>Visit the "Batches" section under the selected course.</li>
                          <li>Choose a batch that fits your schedule.</li>
                          <li>Click "Enroll Now" and complete the registration process.</li>
                          <li>Check your email for batch confirmation details.</li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="faqHeadingThree">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faqCollapseThree"
                        aria-expanded="false"
                        aria-controls="faqCollapseThree"
                      >
                        Can I Switch Between Batches?
                      </button>
                    </h2>
                    <div
                      id="faqCollapseThree"
                      className="accordion-collapse collapse"
                      aria-labelledby="faqHeadingThree"
                      data-bs-parent="#faqAccordion"
                    >
                      <div className="accordion-body px-4">
                        <p>Yes, you can switch batches under certain conditions:</p>
                        <ul>
                          <li>Batch switching is allowed within the first two weeks of joining.</li>
                          <li>Availability in the desired batch must be checked.</li>
                          <li>Submit a batch transfer request through the "My Account" section.</li>
                          <li>Approval will be communicated via email.</li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="faqHeadingFour">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faqCollapseFour"
                        aria-expanded="false"
                        aria-controls="faqCollapseFour"
                      >
                        How Do I View My Batch Schedule?
                      </button>
                    </h2>
                    <div
                      id="faqCollapseFour"
                      className="accordion-collapse collapse"
                      aria-labelledby="faqHeadingFour"
                      data-bs-parent="#faqAccordion"
                    >
                      <div className="accordion-body px-4">
                        <p>To view your batch schedule:</p>
                        <ul>
                          <li>Log in to your account on our platform.</li>
                          <li>Go to the "My Batches" section.</li>
                          <li>Select the active batch you're enrolled in.</li>
                          <li>View the complete schedule, including session timings and topics.</li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="faqHeadingFive">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faqCollapseFive"
                        aria-expanded="false"
                        aria-controls="faqCollapseFive"
                      >
                        Where Can I Learn More About Batch Details?
                      </button>
                    </h2>
                    <div
                      id="faqCollapseFive"
                      className="accordion-collapse collapse"
                      aria-labelledby="faqHeadingFive"
                      data-bs-parent="#faqAccordion"
                    >
                      <div className="accordion-body px-4">
                        <p>You can find detailed information about batches on our "Learn More" page:</p>
                        <ul>
                          <li>Batch start and end dates.</li>
                          <li>Instructor details for each batch.</li>
                          <li>Mode of delivery (live sessions or recorded).</li>
                          <li>Batch capacity and enrollment deadlines.</li>
                        </ul>
                       
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  
    </main>
  
    <Footer/>
  </div>
    )
}
export default LearnMoreBatches;
