import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button } from '@mui/material';
import axios from 'axios';

const EditCourseDetailsModal = ({ open, onClose, courseId, onSave }) => {
    const [currentLecture, setCurrentLecture] = useState({
        title: '',
        classSchedule: '',
        overview: '',
    });

    const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_API_URL });

    // Fetch course details when the modal opens
    useEffect(() => {
        const fetchCourseDetails = async () => {
            if (courseId && open) {
                try {
                    const response = await axiosInstance.get(`/${courseId}`);
                    setCurrentLecture({
                        title: response.data.name || '',
                        classSchedule: response.data.category || '',
                        overview: response.data.description || '',
                    });
                } catch (error) {
                    console.error('Error fetching course details:', error);
                }
            }
        };

        fetchCourseDetails();
    }, [courseId, open, axiosInstance]);

    console.log(currentLecture)

    // Handle input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setCurrentLecture((prevLecture) => ({
            ...prevLecture,
            [name]: value,
        }));
    };

    // Handle save action
    const handleSave = async () => {
        try {
            const formData = {
                name: currentLecture.title,
                description: currentLecture.overview,
                category: currentLecture.classSchedule,
            };

            const response = await axiosInstance.put(`/courses/${courseId}/details`, formData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            onSave(response.data); // Notify parent component
            onClose(); // Close the modal
        } catch (error) {
            console.error('Error saving course details:', error);
        }
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
            <DialogTitle>{currentLecture.title ? 'Edit Details' : 'Add Details'}</DialogTitle>
            <DialogContent>
                <TextField
                    name="title"
                    label="Title"
                    fullWidth
                    margin="dense"
                    value={currentLecture.title}
                    onChange={handleChange}
                />
                <TextField
                    name="classSchedule"
                    label="Class Schedule"
                    fullWidth
                    margin="dense"
                    value={currentLecture.classSchedule}
                    onChange={handleChange}
                />
                <TextField
                    name="overview"
                    label="Overview"
                    fullWidth
                    margin="dense"
                    multiline
                    rows={4}
                    value={currentLecture.overview}
                    onChange={handleChange}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={handleSave} color="primary">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EditCourseDetailsModal;
