import React, { useState } from 'react';
import Navbar from "./Navbar";
import Footer from "./Footer";

const WebinarRegistration = () => {

    return (
        <>
            <Navbar />
            <div class="event mt-4 mb-4">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <nav class="wow fadeInDown animated">
                                <div class="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
                                    <a class="nav-item nav-link active show" id="nav-students-tab" data-toggle="tab" href="#students" role="tab" aria-selected="true">
                                        <div class="nav-content">
                                            <strong>Enroll Students</strong>
                                            <span>Start your learning journey with us today!</span>
                                        </div>
                                    </a>
                                    <a class="nav-item nav-link " id="nav-teachers-tab" data-toggle="tab" href="#teachers" role="tab" aria-selected="false">
                                        <div class="nav-content">
                                            <strong>Enroll Teachers</strong>
                                            <span>Join our community of educators!</span>
                                        </div>
                                    </a>
                                    <a class="nav-item nav-link" id="nav-programs-tab" data-toggle="tab" href="#programs" role="tab" aria-selected="false">
                                        <div class="nav-content">
                                            <strong>Our Programs</strong>
                                            <span>Explore a variety of courses</span>
                                        </div>
                                    </a>
                                    <a class="nav-item nav-link" id="nav-batches-tab" data-toggle="tab" href="#batches" role="tab" aria-selected="false">
                                        <div class="nav-content">
                                            <strong>Available Batches</strong>
                                            <span>Flexible schedules for everyone</span>
                                        </div>
                                    </a>
                                </div>
                            </nav>
                            <div class="tab-content py-3 px-3 px-sm-0 wow fadeInDown animated text-primary" data-animation="fadeInDown animated" data-delay=".2s" id="nav-tabContent">
                                <div class="tab-pane fade active show" id="students" role="tabpanel" aria-labelledby="nav-students-tab">
                                    <div class="row mb-30 text-primary">

                                        <div class="col-lg-3">
                                            <div class="user">
                                                <div class="title">
                                                    <img src="https://www.globalcareercounsellor.com/blog/wp-content/uploads/2018/05/Online-Career-Counselling-course.jpg" alt="img" />
                                                    <h5>Our Courses</h5>
                                                    <p>Explore Learning Opportunities</p>
                                                </div>
                                                <ul>
                                                    <li>Data Science & AI</li>
                                                    <li>Web Development</li>
                                                    <li>Graphic Design</li>
                                                    <li>Digital Marketing</li>
                                                    <li>Language Learning</li>
                                                </ul>
                                            </div>
                                        </div>


                                        <div class="col-lg-9 text-primary">
                                            <div class="event-list-content fix">
                                                <ul>
                                                    <li>Learn from Industry Experts</li>
                                                    <li>Access to Cutting-Edge Tools</li>
                                                </ul>
                                                <h2>Empower Your Education</h2>
                                                <p>
                                                    Enroll in our programs to gain practical knowledge, master new skills, and achieve your goals. Our platform offers a diverse range of courses tailored to suit individual interests and career paths.
                                                </p>
                                                <p>
                                                    Whether you're starting from scratch or looking to upskill, our expert-curated curriculum ensures you stay ahead of the curve. With flexible schedules and interactive resources, learning has never been easier.
                                                </p>
                                                <h3>How to Enroll?</h3>
                                                <ul>
                                                    <li>Browse our <strong>Course Catalog</strong> to find the program that fits your needs.</li>
                                                    <li>Click on <strong>"Enroll Now"</strong> and complete the registration form.</li>
                                                    <li>Make a secure payment to confirm your spot.</li>
                                                    <li>Start your learning journey instantly with access to course materials and live sessions!</li>
                                                </ul>
                                                <a href="/studentEnrollForm" class="btn mt-20 mr-10"><i class="far fa-book"></i> Enroll Now</a>
                                                <a href="/learnMoreStudents" class="btn mt-20">Learn More</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div class="tab-pane fade" id="teachers" role="tabpanel" aria-labelledby="nav-teachers-tab">
                                    <div class="row mb-30">

                                        <div class="col-lg-3">
                                            <div class="user">
                                                <div class="title">
                                                    <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSw4Kw0QCtIEVeWYbHXpudjq_lZYpJleQPiq5kJWduy6CMfq27BjZ9O1UemZZf3eodjvVU&usqp=CAU" alt="img" />
                                                    <h5>About Our Platform</h5>
                                                    <p>Virtual School & Online Classes</p>
                                                </div>
                                                <ul>
                                                    <li>Interactive Virtual Classrooms</li>
                                                    <li>Real-Time Teaching Tools</li>
                                                    <li>Global Student Base</li>
                                                    <li>Support for Live & Pre-recorded Sessions</li>
                                                </ul>
                                            </div>
                                        </div>


                                        <div class="col-lg-9">
                                            <div class="event-list-content fix">
                                                <ul>
                                                    <li>Teach Students Worldwide</li>
                                                    <li>Create Impactful Courses</li>
                                                </ul>
                                                <h2>Inspire as a Mentor</h2>
                                                <p>
                                                    Join our global network of educators and transform the way students learn. As a teacher, you can share your knowledge, design engaging courses, and interact with students from around the world in a virtual environment.
                                                </p>
                                                <h3>Why Teach with Us?</h3>
                                                <ul>
                                                    <li>Access to an advanced virtual teaching platform with tools for live classes, Q&A, and progress tracking.</li>
                                                    <li>Opportunities to grow professionally and reach a global audience.</li>
                                                    <li>Flexible schedules to suit your availability.</li>
                                                    <li>Earn competitive rewards while making a difference in students' lives.</li>
                                                </ul>
                                                <h3>How to Get Started?</h3>
                                                <ol>
                                                    <li>Submit your application by clicking on <strong>"Apply Now."</strong></li>
                                                    <li>Attend a short onboarding session to understand our platform.</li>
                                                    <li>Create and upload your course material or start live sessions.</li>
                                                    <li>Engage with students and track their progress with interactive tools.</li>
                                                </ol>
                                                <a href="/teacherEnrollForm" class="btn mt-20 mr-10"><i class="far fa-chalkboard"></i> Apply Now</a>
                                                <a href="#" class="btn mt-20">Learn More</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div class="tab-pane fade" id="programs" role="tabpanel" aria-labelledby="nav-programs-tab">
                                    <div class="row mb-30">

                                        <div class="col-lg-3">
                                            <div class="user">
                                                <div class="title">
                                                    <img src="https://lh6.googleusercontent.com/LwX-E77Q3KVsZrVJK31SgYotepCYwt2ZTpCK4haD6abUg4yLDqvzQkp2JRERwWdKaZzHX94xgzscEUvO-GSAX_mqmKGpHX1zUw4v19zLXPR7iWVt4NScReaydDTdvy8JKUAtHq_r" alt="img" />
                                                    <h5>Program Overview</h5>
                                                    <p>Explore Diverse Learning Opportunities</p>
                                                </div>
                                                <ul>
                                                    <li>Technology Programs (AI, Data Science, Web Development)</li>
                                                    <li>Arts & Design Courses (Graphic Design, Photography, Animation)</li>
                                                    <li>Business & Management (Marketing, Finance, Entrepreneurship)</li>
                                                    <li>Specialized Kits (Coding Kits, Robotics Kits, Art Supplies)</li>
                                                </ul>
                                            </div>
                                        </div>


                                        <div class="col-lg-9">
                                            <div class="event-list-content fix">
                                                <ul>
                                                    <li>Hands-On Learning Experience</li>
                                                    <li>Real-World Applications</li>
                                                </ul>
                                                <h2>Discover Your Path</h2>
                                                <p>
                                                    Our programs are designed to help you unlock your potential and achieve your goals. Whether you’re interested in building technical skills, exploring creative arts, or mastering business strategies, we offer the perfect courses and resources tailored to your needs.
                                                </p>
                                                <h3>What We Offer:</h3>
                                                <ul>
                                                    <li><strong>Technology:</strong> Dive into trending fields like Artificial Intelligence, Full-Stack Web Development, and Cybersecurity.</li>
                                                    <li><strong>Creative Arts:</strong> Learn photography, video editing, or digital illustration using industry-standard tools.</li>
                                                    <li><strong>Business:</strong> Gain insights into strategic management, digital marketing, and leadership.</li>
                                                    <li><strong>Custom Kits:</strong> Enhance your learning experience with specialized kits like Robotics Kits for STEM projects, Coding Kits for developers, and Art Supplies for design enthusiasts.</li>
                                                </ul>
                                                <h3>Why Choose Us?</h3>
                                                <ul>
                                                    <li>Programs designed by industry experts and aligned with current trends.</li>
                                                    <li>Access to study materials, project-based learning, and live mentorship.</li>
                                                    <li>Certifications to boost your professional credibility.</li>
                                                    <li>Exclusive kits shipped to your doorstep to complement your learning.</li>
                                                </ul>
                                                <a href="/Courses1" class="btn mt-20 mr-10"><i class="far fa-compass"></i> Explore Programs</a>
                                               
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="tab-pane fade" id="batches" role="tabpanel" aria-labelledby="nav-batches-tab">
                                    <div class="row mb-30">

                                        <div class="col-lg-3">
                                            <div class="user">
                                                <div class="title">
                                                    <img src="https://d3bat55ebwjhsf.cloudfront.net/expert-article/user_abhilasharai29%40gmail.comrai/advantages_of_virtual_learning.jpg" alt="img" />
                                                    <h5>Grades 6 to 12 - Experienced Educator</h5>
                                                    <p>Specializing in teaching children about robotics.</p>
                                                </div>
                                                <ul>
                                                    <li>Interactive Lessons for Grades 6-8</li>
                                                    <li>In-depth Coaching for Grades 9-10</li>
                                                    <li>Advanced Preparation for Grades 11-12 (All Streams)</li>
                                                </ul>
                                            </div>
                                        </div>



                                        <div class="col-lg-9">
                                            <div class="event-list-content fix">
                                                <ul>
                                                    <li>Morning, Afternoon & Evening Batches</li>
                                                    <li>Weekend-Only Options</li>
                                                </ul>
                                                <h2>Tailored Learning Schedules</h2>
                                                <p>
                                                    We run various batches to accommodate students from different time zones and schedules. Whether you prefer studying in the morning, evening, or on weekends, our classes are designed to fit your lifestyle.
                                                </p>

                                                <h3>Types of Classes We Offer:</h3>
                                                <ul>
                                                    <li><strong>Live Interactive Classes:</strong> Join real-time sessions with expert instructors and fellow learners.</li>
                                                    <li><strong>Recorded Sessions:</strong> Access pre-recorded lectures anytime for self-paced learning.</li>
                                                    <li><strong>Hybrid Classes:</strong> A mix of live and recorded classes for maximum flexibility.</li>
                                                </ul>

                                                <h3>Batch Categories:</h3>
                                                <ul>
                                                    <li><strong>Beginner Batches:</strong> For students new to the subject, covering fundamentals.</li>
                                                    <li><strong>Advanced Batches:</strong> For experienced learners focusing on specialized topics.</li>
                                                    <li><strong>Crash Courses:</strong> Short-term intensive classes for quick learning.</li>
                                                </ul>

                                                <h3>Why Choose Us?</h3>
                                                <ul>
                                                    <li>Flexible timings to match your daily routine.</li>
                                                    <li>Small batch sizes to ensure personalized attention.</li>
                                                    <li>Real-time Q&A and doubt-solving sessions.</li>
                                                    <li>Progress tracking to help you stay on top of your goals.</li>
                                                </ul>

                                                <p>
                                                    Choose the batch that works best for you and start learning with expert guidance. Receive updates on your class timings and resources through our dedicated platform.
                                                </p>

                                                <a href="/batchDetails" class="btn mt-20 mr-10"><i class="far fa-calendar-alt"></i> View Available Batches</a>
                                                <a href="/learMoreBatches" class="btn mt-20">Learn More</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>


    );
};

export default WebinarRegistration;
