import React, { useEffect, useState } from "react";
import Navbar from "../LandingPage/Navbar";
import { useSelector, useDispatch } from "react-redux";
import { addCart, delCart } from "../../redux/cart/cartAction";
import { Link } from "react-router-dom";
import axios from "axios";
import Footer from "../LandingPage/Footer";
import logo from "../LandingPage/logo10.png";

const Cart = () => {
  const state = useSelector(state => state.cart);
  console.log(state);
  const [userData, setUserData] = useState(null);
  const [downloadLink, setDownloadLink] = useState(null); // For invoice link

  useEffect(() => {
    const userDataFromStorage = localStorage.getItem("user");
    if (userDataFromStorage) {
      setUserData(JSON.parse(userDataFromStorage));
    }
  }, []);

  const userId = userData ? userData._id : null;
  const userName = userData ? userData.userName : null;
  const email = userData ? userData.email : null;
  const address = userData?.address || null;
  const dispatch = useDispatch();
  const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_API_URL });

// components/Cart.js

const checkoutHandler = async (amount, items) => {
  if (!userData) {
    console.error("User data not loaded.");
    return;
  }

  try {
    // Get Razorpay key
    const { data: { key } } = await axiosInstance.get("/api/getkey");

    // Calculate the total course price and total kit price separately
    let totalCoursePrice = 0;
    let totalKitPrice = 0;
    let kitNames = []; // Store the names of all kits
    
    items.forEach((item) => {
      const coursePrice = item.product.coursePrice || item.product.price || 0;
      totalCoursePrice += coursePrice * item.qty;
    
      if (item.product.kits && item.product.kits.length > 0) {
        item.product.kits.forEach((kit) => {
          totalKitPrice += kit.price || 0;
    
          // Add the kit name to the kitNames array
          if (kit.name && !kitNames.includes(kit.name)) {
            kitNames.push(kit.name);
          }
        });
      }
    });
    
    console.log("Total Course Price:", totalCoursePrice);
    console.log("Total Kit Price:", totalKitPrice);
    console.log("Kit Names:", kitNames);
    

    // Total amount to be charged is the sum of course price and kit price
    const totalAmount = totalCoursePrice + totalKitPrice;
    const gstAmount = totalAmount * 0.18;  // 18% GST
    const totalAmountWithGST = totalAmount + gstAmount;  // Adding GST to the total
    

    const { data: { order, invoiceLink, paymentId } } = await axiosInstance.post("/api/checkout", { amount: totalAmountWithGST });
    console.log("order ",order);

    const options = {
      key,
      amount: order.amount, // Pass the total amount
      currency: "INR",
      name: "Kalam Lab",
      image: logo,
      order_id: order.id,
      callback_url: "https://kalamlab.com/api/paymentverification", // Corrected the URL
      prefill: {
        name: userName,
        email: email,
      },
      notes: {
        address: "Razorpay Corporate Office",
        course_price: totalCoursePrice, // Pass total course price separately
        kit_price: totalKitPrice, // Pass total kit price separately
      },
      theme: {
        color: "#121212",
      },
      handler: function (response) {
        // After successful payment, handle the payment verification
        axiosInstance
          .post("/api/paymentverification", {
            razorpay_order_id: response.razorpay_order_id,
            razorpay_payment_id: response.razorpay_payment_id,
            razorpay_signature: response.razorpay_signature,
            userId,
            courseIds: items.map((item) => item.product._id), 
            amount: totalAmount, // Send the total amount (course + kit)
            userName, 
            email,
            address,
            courseName: items
              .map((item) => {
                const name = item.product.name ? `Name: ${item.product.name}` : "";
                const courseName = item.product.courseName ? `Course Name: ${item.product.courseName}` : "";
                return [name, courseName].filter(Boolean).join(" / ");
              })
              .join(", "),
            quantity: items.map((item) => item.qty), // Send quantities to backend
            coursePrice: totalCoursePrice, // Send total course price
            kitPrice: totalKitPrice, // Send total kit price
            kitName : kitNames
          })
          .then((res) => {
            const invoiceLink = res.data.invoiceLink;
            window.location.href = `https://kalamlab.com/paymentsuccess?reference=${response.razorpay_payment_id}&invoice=${encodeURIComponent(invoiceLink)}`;
            console.log("DOWNLOAD LINK", invoiceLink);
          })
          .catch((err) => {
            console.error("Payment verification error:", err);
          });
      },
    };

    // Open Razorpay if it's loaded
    if (window.Razorpay) {
      const razor = new window.Razorpay(options);
      razor.open();
    } else {
      console.error("Razorpay SDK not loaded");
    }
  } catch (error) {
    console.error("Checkout error:", error);
  }
};



  const EmptyCart = () => (
    <div className="container">
      <div className="row">
        <div className="col-md-12 py-5 bg-light text-center">
          <h4 className="p-3 display-5">Your Cart is Empty</h4>
          <Link to="/" className="btn btn-outline-dark mx-4">
            <i className="fa fa-arrow-left"></i> Continue Shopping
          </Link>
        </div>
      </div>
    </div>
  );

  const addItem = (product) => {
    dispatch(addCart(product.product));
  };

  const removeItem = (product) => {
    dispatch(delCart(product.product));
  };

  const ShowCart = () => {
   
    let gst = 0.0;
    const [total, setTotal] = useState({ total_price: 0, total_qty: 0 });
    
    useEffect(() => {
      if (state.length > 0) {
        let total_price = 0;
        let total_item_qty = 0;
    
        state.forEach((item) => {
          const price = item.product.coursePrice
            ? item.product.coursePrice
            : item.product.price || 0;
    
          // Add the kit price to the total price
          if (item.product.kits && item.product.kits.length > 0) {
            item.product.kits.forEach((kit) => {
              total_price += kit.price || 0; // Add kit price to the total
            });
          }
    
          total_price += price * item.qty; // Add product price * quantity
          total_item_qty += item.qty;
        });
    
        setTotal({ total_price, total_qty: total_item_qty });
      }
    }, [state]);
    
    // 18% GST Calculation after adding kit price and product price
    gst = 0.18 * total.total_price;
    
    // Add the GST to the total price
    const totalWithGST = total.total_price + gst;
    
    // Round the GST and the total with GST to three decimal places
    const gstFormatted = gst.toFixed(3); // Format GST to 3 decimal places
    const totalWithGSTFormatted = totalWithGST.toFixed(3); // Format total price + GST to 3 decimal places

    
    return (
      <div>
        <section className="h-100 gradient-custom">
          <div className="container py-5">
            <div className="row">
              <div className="col-md-8">
              {state.map((item) => (
                <div className="card mb-4">
  <div className="card-body">
    <div className="row align-items-center">
      {/* Course Thumbnail */}
      <div className="col-lg-2 col-md-3">
        <img
          src={item.product.image || item.product.courseThumbnail}
          alt={item.product.name || item.product.courseName}
          className="img-fluid rounded"
        />
      </div>

      {/* Course Details */}
      <div className="col-lg-6 col-md-5">
        <p className="mb-1">
          <strong>{item.product.name || item.product.courseName}</strong>
        </p>
       
        <p className="text-muted mb-1">
          <strong>4.5</strong> ★ (41,361 ratings)
        </p>
        <p className="text-muted mb-1">
          {item.product.duration || "Duration"} •{" "}
          {item.product.level || "All Levels"}
        </p>
        {/* Kits Details */}
        {item.product.kits && item.product.kits.length > 0 && (
          <p className="text-muted">
            Kits:{" "}
            {item.product.kits.map((kit, index) => (
              <span key={kit._id}>
                {kit.name} ({kit.price ? `₹${kit.price}` : "N/A"})
                {index < item.product.kits.length - 1 && ", "}
              </span>
            ))}
          </p>
        )}
      </div>

      {/* Price and Actions */}
      <div className="col-lg-4 col-md-4 text-end">
        <p className="mb-1">
          <strong>₹{item.product.coursePrice}</strong>
        </p>
        <button
          className="btn btn-link text-danger p-0"
          onClick={() => removeItem(item)}
        >
          Remove
        </button>
      </div>
    </div>
  </div>
</div>

   
      ))}
              </div>
              <div className="col-md-4">
  <div className="card mb-4">
    <div className="card-header py-3 bg-light">
      <h5 className="text-dark mb-0">Order Summary</h5>
    </div>
    <div className="card-body">
      <ul className="list-group list-group-flush">
        {/* Products and Quantity */}
        <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0 pb-0">
  Course ({total.total_qty}) <span>₹{state.reduce((acc, item) => {
    const price = item.product.coursePrice || item.product.price || 0;
    return acc + (price * item.qty); // Accumulate the total price for the course
  }, 0)}</span>
</li>

        {/* Kit Price (if any) */}
        {state.some((item) => item.product.kits && item.product.kits.length > 0) && (
          <li className="list-group-item d-flex justify-content-between align-items-center px-0">
            Kit Price <span>₹{state.reduce((acc, item) => {
              if (item.product.kits && item.product.kits.length > 0) {
                acc += item.product.kits.reduce((kitAcc, kit) => kitAcc + (kit.price || 0), 0);
              }
              return acc;
            }, 0)}</span>
          </li>
        )}

        {/* GST */}
        <li className="list-group-item d-flex justify-content-between align-items-center px-0">
  GST (18%) <span>₹{gstFormatted}</span>
</li>

        {/* Total amount */}
        <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0 mb-3">
          <div>
            <strong>Total amount</strong>
          </div>
          <span>
            <strong>₹{totalWithGSTFormatted}</strong>
          </span>
        </li>
      </ul>

      {/* Buy Now Button */}
      <button
        className="btn btn-dark btn-lg btn-block"
        onClick={() => checkoutHandler(total.total_price + gst, state.map((item) => ({
          ...item,
          qty: item.qty // Pass quantity along with each item
        })))}
      >
        Buy Now
      </button>

      {/* Download Invoice Link */}
      {downloadLink && (
        <div className="formbold-mt-5">
          <a href={downloadLink} download="invoice.pdf" className="btn btn-outline-dark mt-3">
            Download Invoice
          </a>
        </div>
      )}
    </div>
  </div>
</div>

            </div>
          </div>
        </section>
      </div>
    );
  };
  

  return (
    <div>
      <Navbar />
      <div className="container my-3 py-3">
        {state && state.length > 0 ? <ShowCart /> : <EmptyCart />}
      </div>
      <Footer />
    </div>
  );
};

export default Cart;
