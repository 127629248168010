import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Home, CalendarToday, Folder, Settings, School } from "@mui/icons-material";
import { Avatar, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

const SideBar = () => {
  const [coursedata, setData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_API_URL });

  useEffect(() => {
    const getCourses = async () => {
      const response = await axiosInstance.post("/data-courses");
      setData(response.data.data);
    };

    getCourses();
  }, []);

  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const userDataFromStorage = localStorage.getItem("user");
    if (userDataFromStorage) {
      try {
        const parsedData = JSON.parse(userDataFromStorage);
        setUserData(parsedData);
      } catch (error) {
        console.error("Failed to parse user data:", error);
      }
    }
  }, []);

  const handleLogout = () => {
    localStorage.clear("user");
    localStorage.clear("auth_token");
    dispatch({ type: "CLEAR__USER" });
    navigate("/login");
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <div className="app">
      <div className="topbar">
        <div style={{ display: "flex", alignItems: "center" }}>
          <School sx={{ fontSize: 36, color: "#007bff", marginRight: "8px" }} />
          <p style={{ margin: 0, fontSize: 24 }}>Classroom</p>
        </div>

        {userData ? (
          <div>
            <IconButton
              onClick={handleClick}
              sx={{ color: "#007bff", fontSize: "12px", display: "flex", alignItems: "center" }}
            >
              <Typography
                variant="body1"
                sx={{
                  marginRight: "14px",
                  fontSize: "14px",
                  backgroundColor: "#0d6efd",
                  color: "white",
                  borderRadius: "18px",
                  padding: "8px 12px",
                  display: "inline-block",
                }}
              >
                {userData.firstName || userData.userName} <i className="bi bi-chevron-down"></i>
              </Typography>
            </IconButton>

            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              PaperProps={{
                sx: {
                  minWidth: 9,
                  color: "red",
                },
              }}
            >
              <MenuItem>
                <a href="/">Home</a>
              </MenuItem>
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
          </div>
        ) : (
          <Link to="/login" className="nav-item nav-link active">
            Login
          </Link>
        )}
      </div>

      <div className="sidebar">
        <a
          href="/virtualClassroom"
          className="active"
          style={{
            display: "flex",
            alignItems: "center",
            padding: "8px 0",
           
          }}
        >
          <Home sx={{ fontSize: 24, marginRight: "14px", color: "#0d6efd" }} />
          Home
        </a>
        <a
          href="/calender"
          style={{
            display: "flex",
            alignItems: "center",
            padding: "8px 0",
          
          }}
        >
          <CalendarToday sx={{ fontSize: 24, marginRight: "14px", color: "#0d6efd" }} />
          Calendar
        </a>
        <h3>Enrolled</h3>
        {coursedata.map((course) => (
          <Link
  
            style={{ display: "flex", alignItems: "center", padding: "8px 0", textDecoration: "none", color: "inherit" }}
          >
            <Avatar
              sx={{
                backgroundColor: "#0d6efd",
                color: "white",
                fontSize: 24,
                marginRight: "14px",
              }}
            >
              {course.courseName.charAt(0)}
            </Avatar>
            {course.courseName}
          </Link>
        ))}
        <div className="bottom-link">
          <a
            href="#"
            style={{
              display: "flex",
              alignItems: "center",
              padding: "8px 0",
        
            }}
          >
            <Folder sx={{ fontSize: 24, marginRight: "14px", color: "#0d6efd" }} />
            Archived classes
          </a>
          <a
            href="#"
            style={{
              display: "flex",
              alignItems: "center",
              padding: "8px 0",
        
            }}
          >
            <Settings sx={{ fontSize: 24, marginRight: "14px", color: "#0d6efd" }} />
            Settings
          </a>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
