import React, { useState, useEffect } from "react";
import { Card, Box, Typography, Avatar, Grid, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import axios from "axios";
import SideBar from "./SideBar";
import { Link, useNavigate } from "react-router-dom";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";

const ClassroomCard = ({ title, className, teacher, avatar, bgColor, textColor, courseImage, courseId }) => {
  const [userData, setUserData] = useState(null);
  const [purchasedCourses, setPurchasedCourses] = useState([]);
  const [popupOpen, setPopupOpen] = useState(false);
  const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_API_URL });
  const navigate = useNavigate();

  useEffect(() => {
    const userDataFromStorage = localStorage.getItem("user");
    if (userDataFromStorage) {
      try {
        const parsedData = JSON.parse(userDataFromStorage);
        setUserData(parsedData);
      } catch (error) {
        console.error("Failed to parse user data:", error);
      }
    }
  }, []);

  const userId = userData ? userData._id : null;

  useEffect(() => {
    const fetchPurchasedCourses = async () => {
      try {
        const token = localStorage.getItem('auth_token');
        const response = await axiosInstance.get("/api/purchased-courses", {
          headers: {
            Authorization: "Bearer " + token,
          }
        });

        console.log('Purchased courses response:', response.data); 

        setPurchasedCourses(response.data.courses.flat());
      } catch (error) {
        console.error("Error fetching purchased courses:", error);
      }
    };

    if (userId) {
      fetchPurchasedCourses();
    }
  }, [userId]);

  console.log("Purchased courses:", purchasedCourses);
console.log("Current course title:", title);
console.log(
  "Match found:",
  purchasedCourses.data?.some((course) => course.courseName === title)
);


  return (
    <>
<Link
  key={courseId}
  to={
    purchasedCourses?.some(
      (course) =>
        course.data?.courseName?.toLowerCase().trim() === title.toLowerCase().trim()
    )
      ? `/content/${courseId}`
      : "#"
  }
  onClick={(event) => {
    const isPurchased = purchasedCourses?.some(
      (course) =>
        course.data?.courseName?.toLowerCase().trim() === title.toLowerCase().trim()
    );

    console.log("Purchased courses:", purchasedCourses);
    console.log("Current course title:", title);
    console.log("Is purchased:", isPurchased);

    if (!isPurchased) {
      event.preventDefault(); // Prevent navigation
      setPopupOpen(true); // Show popup
    }
  }}
  style={{
    display: "flex",
    alignItems: "center",
    padding: "8px 0",
    textDecoration: "none",
    color: "inherit",
  }}
>
      <Card
        sx={{
          width: 345,
          borderRadius: "10px",
          overflow: "hidden",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          position: "relative",
          height: 250,
        }}
      >
        <Box
          sx={{
            position: "relative",
            backgroundImage: `url(${courseImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            height: "100px",
            padding: "16px",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              background: "rgba(0, 0, 0, 0.4)",
              zIndex: 0,
            }}
          />
          <Typography
            variant="h6"
            sx={{
              fontWeight: "bold",
              lineHeight: 1.2,
              color: "white",
              position: "relative",
              zIndex: 1,
            }}
          >
            {title}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              marginTop: "8px",
              color: "white",
              position: "relative",
              zIndex: 1,
            }}
          >
            {className}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              marginTop: "4px",
              color: "white",
              position: "relative",
              zIndex: 1,
            }}
          >
            {teacher}
          </Typography>
        </Box>

        <Box
          sx={{
            flexGrow: 1,
            backgroundColor: "#ffffff",
            height: "50px",
            position: "relative",
          }}
        >
          <Avatar
            sx={{
              position: "absolute",
              right: "16px",
              top: "-24px",
              bgcolor: bgColor,
              color: textColor,
              fontWeight: "bold",
              width: 60,
              height: 60,
              fontSize: "40px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            }}
          >
            {avatar}
          </Avatar>
        </Box>

        <Grid
          container
          justifyContent="flex-end"
          alignItems="center"
          sx={{
            padding: "10px",
            backgroundColor: "#ffffff",
            borderTop: "1px solid rgba(0, 0, 0, 0.1)",
          }}
        >
          <Box>
            <IconButton>
              <CalendarTodayIcon sx={{ fontSize: 20, color: "#000" }} />
            </IconButton>
            <IconButton>
              <FolderOpenIcon sx={{ fontSize: 20, color: "#000" }} />
            </IconButton>
          </Box>
        </Grid>
      </Card>
</Link>

      {/* Popup */}
      <Dialog open={popupOpen} onClose={() => setPopupOpen(false)}>
        <DialogTitle>Access Denied</DialogTitle>
        <DialogContent>
          <Typography>You have not purchased this course yet.</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setPopupOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

// VirtualClassroom Component
const VirtualClassroom = () => {
  const [coursedata, setData] = useState([]);
  const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_API_URL });

  useEffect(() => {
    const getProducts = async () => {
      const response = await axiosInstance.post("/data-courses");
      setData(await response.data.data);
    };

    getProducts();
  }, []);

  console.log(coursedata);

  return (
    <div>
  
        <SideBar />
        <div className="main-content">
          <div className="card-grid">
            <Grid container spacing={3} sx={{ padding: "20px" }}>
              {coursedata.length > 0 ? (
                coursedata.map((course, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <ClassroomCard
                      title={course.courseName}
                      className={course.type || "General Class"}
                      teacher={course.teacher?.userName || "Unknown Teacher"}
                      avatar={course.courseName.charAt(0)}
                      courseImage={course.courseThumbnail || "default-image-url.jpg"} 
                      bgColor="#0d6efd"
                      courseId={course._id}
                    />
                  </Grid>
                ))
              ) : (
                <Typography variant="h6" align="center">
                  No courses available
                </Typography>
              )}
            </Grid>
          </div>
        </div>
      </div>

  );
};

export default VirtualClassroom;
