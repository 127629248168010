import React, { useState, useEffect } from "react";
import axios from "axios";
import { Box, TextField, Alert, Typography } from "@mui/material";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from "@mui/material";

import SideBar from "../SideBar";

const CourseAssignmentManager = () => {
  const [courses, setCourses] = useState([]);
  const [selectedCourseId, setSelectedCourseId] = useState(null);
  const [message, setMessage] = useState("");

  const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_API_URL });

  // Fetch courses on component mount
  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await axiosInstance.get("/get-data-courses", {
          headers: { Authorization: "Bearer " + localStorage.getItem("auth_token") },
        });
        setCourses(response.data.courses);
      } catch (err) {
        console.error("Error fetching courses:", err);
      }
    };
    fetchCourses();
  }, []);

  console.log(courses)

  const renderCourses = () => {
    return courses.map((course) => (
      <div className="col-md-6 col-xl-4 mb-3" key={course._id}>
        <div className="card widget-content bg-midnight-bloom" onClick={() => setSelectedCourseId(course._id)}>
          <div className="widget-content-wrapper text-white">
            <div className="widget-content-left">
              <img
                src={course.courseThumbnail}
                alt={course.courseName}
                style={{
                  width: "100%",
                  height: "120px",
                  objectFit: "cover",
                  borderRadius: "8px",
                }}
              />
              <div className="widget-heading mt-4">{course.courseName}</div>
            </div>
          </div>
        </div>
      </div>
    ));
  };

  return (
    <div>
      <div className="app-container app-theme-white body-tabs-shadow fixed-sidebar fixed-header" id="appContent">
        <div className="app-main">
          <SideBar />
          <div className="col mt-4">
            {selectedCourseId ? (
              <AddAssignmentForm
                courseId={selectedCourseId}
                onBack={() => setSelectedCourseId(null)}
                renderCourses={renderCourses}
                setMessage={setMessage}
              />
            ) : (
              <div className="row">{renderCourses()}</div>
            )}
<TableContainer component={Paper} className="mt-4 mb-4">
  <Table>
    <TableHead>
      <TableRow>
        <TableCell>Course Name</TableCell>
        <TableCell>Title</TableCell>
        <TableCell>Description</TableCell>
        
        <TableCell>Resources</TableCell>
        <TableCell>Due Date</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {courses.map((course) =>
        course.assignments.map((assignment) => (
          <TableRow key={assignment._id}>
            <TableCell>{course.courseName}</TableCell>
            <TableCell>{assignment.title}</TableCell>
            <TableCell>{assignment.description.split(' ').slice(0, 15).join(' ') + (assignment.description.split(' ').length > 15 ? '...' : '')}</TableCell>

            
            <TableCell>
            {assignment.resources}
            </TableCell>
            <TableCell>{new Date(assignment.dueDate).toLocaleDateString()}</TableCell>
          </TableRow>
        ))
      )}
    </TableBody>
  </Table>
</TableContainer>

          </div>
        </div>
      </div>
    </div>
  );
};

const AddAssignmentForm = ({ courseId, message, renderCourses, setMessage }) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [dueDate, setDueDate] = useState("");
  const [resources, setResources] = useState("");
  const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_API_URL });

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosInstance.post(
        `/courses/${courseId}/assignments`,
        {
          title,
          description,
          dueDate,
          resources: resources ? resources.split(",") : [],
        },
        { headers: { Authorization: "Bearer " + localStorage.getItem("auth_token") } }
      );
      setMessage(response.data.message);
      setTitle("");
      setDescription("");
      setDueDate("");
      setResources("");
    } catch (err) {
      setMessage(err.response?.data?.error || "Something went wrong.");
    }
  };

  return (
    <div>
 
      <div className="row mb-4">{renderCourses()}</div>
      <Box
        sx={{
          maxWidth: "1190px",
          margin: "auto",
          p: 4,
          border: "1px solid #ddd",
          borderRadius: "8px",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          backgroundColor: "#fff",
        }}
      >
    
        {message && (
          <Alert severity={message.includes("wrong") ? "error" : "success"} sx={{ mb: 2 }}>
            {message}
          </Alert>
        )}
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            label="Title"
            variant="outlined"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
            sx={{ mb: 3 }}
          />
          <TextField
            fullWidth
            label="Description"
            variant="outlined"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            multiline
            rows={4}
            sx={{ mb: 3 }}
          />
          <TextField
            fullWidth
            label="Due Date"
            type="date"
            value={dueDate}
            onChange={(e) => setDueDate(e.target.value)}
            InputLabelProps={{ shrink: true }}
            sx={{ mb: 3 }}
          />
          <TextField
            fullWidth
            label="Resources (comma-separated URLs)"
            variant="outlined"
            value={resources}
            onChange={(e) => setResources(e.target.value)}
            sx={{ mb: 3 }}
          />
          <Button type="submit" variant="contained" color="primary">
            Add Assignment
          </Button>
        </form>
      </Box>
    </div>
  );
};

export default CourseAssignmentManager;
