import React, { useEffect, useState } from 'react';
import axios from 'axios'; 
import Navbar from './Navbar';
import Footer from './Footer';

const BatcheDetails = () => {
  const [courses, setCourses] = useState([]);
  const [batches, setBatches] = useState([]); // State for storing batches
  const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_API_URL });

  useEffect(() => {
    let componentMounted = true;

    // Fetch courses data
    const getCourses = async () => {
      try {
        const response = await axiosInstance.post('/data-courses');
        if (componentMounted) {
          setCourses(response.data.data);
        }
      } catch (error) {
        console.error('Error fetching courses:', error);
      }
    };

    // Fetch batch data
    const getBatches = async () => {
      try {
        const response = await axiosInstance.get('/batch/details'); // Adjust the endpoint accordingly
    
          setBatches(response.data); // Assuming the batch data is in `data` key
        
      } catch (error) {
        console.error('Error fetching batches:', error);
      }
    };

    getCourses();
    getBatches();

    return () => {
      componentMounted = false;
    };
  }, []);

  console.log(batches)

  return (
    <>
      <Navbar />
      <div className="container">
        <div className="row">
          <div className="col-lg-3 mt-4">
            <div className="mb-4">
              <div className="input-group">
                <input placeholder="Search for..." type="text" className="form-control" />
                <div className="input-group-append">
                  <button className="btn btn-secondary">
                    <i className="fa fa-search"></i>
                  </button>
                </div>
              </div>
            </div>

            {/* Favorites */}
            <div className="mb-4">
              <div className="small mb-3">Favorites</div>
              <ul className="nav flex-column">
                <li className="nav-item">
                  <a href="#" className="nav-link active">
                    <i className="fa fa-fw fa-line-chart mr-2"></i>Overview
                  </a>
                </li>
                <li className="nav-item">
                  <a href="#" className="nav-link">
                    <i className="fa fa-fw fa-calendar-o mr-2"></i>Calendar
                  </a>
                </li>
              </ul>
            </div>

            <div className="mb-4">
              <div className="small mb-3">Courses</div>
              <ul className="nav flex-column">
                {courses.map((course) => (
                  <li key={course.id} className="nav-item">
                    <a href="#" className="d-flex nav-link">
                      <div className="media">
                        <div className="media-body">
                          <div className="mt-0">{course.courseName}</div>
                          <span className="small">{course.type}</span>
                        </div>
                      </div>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className="col-lg-9 mt-4">
            <div className="mb-3 Card_custom-card--border_5wJKy card">
              <div className="table-responsive-xl m-4">
                <table className="mb-0 table table-hover">
                  <thead>
                    <tr>
                      <th className="align-middle bt-0">Star</th>
                      <th className="align-middle bt-0">Courses</th>
                      <th className="align-middle bt-0">Status</th>
                      <th className="align-middle bt-0">Enrolled Students</th>
                      <th className="align-middle bt-0">People</th>
                      <th className="align-middle bt-0 text-right">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {batches.map((batch) => (
                      <tr key={batch._id}>
                        <td className="align-middle">
                          <div className="text-inverse">
                            <a href="#">
                              <i className="fa fa-fw fa-lg fa-star-o"></i>
                            </a>
                          </div>
                        </td>
                        <td className="align-middle">
                          <div>
                            <a href="#">{batch.course}</a>
                          </div>
                         
                        </td>
                        <td className="align-middle">
                          <span className={`badge badge-success badge-pill`}>
                           Active
                          </span>
                        </td>
                        <td className="align-middle">
                          <div className="mb-2 progress" style={{ height: '5px' }}>
                            <div
                              className="progress-bar"
                              role="progressbar"
                              aria-valuenow="30"
                              aria-valuemin="0"
                              aria-valuemax="100"
                              style={{ width: `20` }}
                            ></div>
                          </div>
                          <div>
  Batch Time: <span className="text-inverse">
    {new Date(batch.date).toLocaleDateString('en-US', {
      weekday: 'long', // "Monday"
      year: 'numeric', // "2025"
      month: 'long', // "January"
      day: 'numeric' // "8"
    })}
  </span>
</div>

                          <div>Enrolled Students: <span className="text-inverse">1</span></div>
                        </td>
                        <td className='text-center'>
                        <strong>
                               10
                             </strong>
                        </td>
                        <td className="align-middle text-right">
                          <div className="btn-group">
                            <button type="button" aria-haspopup="true" aria-expanded="false" className="dropdown-toggle btn btn-link">
                              <i className="fa fa-gear"></i>
                            </button>
                            <div tabindex="-1" role="menu" aria-hidden="true" className="dropdown-menu dropdown-menu-right">
                              <button type="button" tabindex="0" className="dropdown-item">
                                <i className="fa fa-fw fa-folder-open mr-2"></i>View
                              </button>
                              <button type="button" tabindex="0" className="dropdown-item">
                                <i className="fa fa-fw fa-ticket mr-2"></i>Add Task
                              </button>
                              <button type="button" tabindex="0" className="dropdown-item">
                                <i className="fa fa-fw fa-paperclip mr-2"></i>Add Files
                              </button>
                              <div tabindex="-1" className="dropdown-divider"></div>
                              <button type="button" tabindex="0" className="dropdown-item">
                                <i className="fa fa-fw fa-trash mr-2"></i>Delete
                              </button>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default BatcheDetails;
