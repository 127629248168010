import React, { useState } from "react";
import Navbar from "../LandingPage/Navbar";
import Footer from "../LandingPage/Footer";

const LearnMoreStudents = () => {
  
  
  return(
        <div> 
        <Navbar/>
         <main class="main">
         <section id="hero" class="hero section light-background">
  <img src="https://www.washingtonpost.com/wp-apps/imrs.php?src=https://arc-anglerfish-washpost-prod-washpost.s3.amazonaws.com/public/4ZK4RFUCFYI6TNMF4NVRNJJRVI.jpg&w=1200" data-aos="fade-in" />  
  <div class="container position-relative">   
    <div class="content row gy-4 mt-4 py-4">
      <div class="col-lg-4 d-flex align-items-stretch py-4">
        <div class="why-box" data-aos="zoom-out" data-aos-delay="200">
          <h3>Why Join Our Virtual School?</h3>
          <p class="text-white">
            At KalamLab, we offer an immersive online learning experience where students explore the world of AI and Robotics through engaging, hands-on projects. Whether you're building a robot or programming an AI system, our virtual classroom provides the tools to foster creativity, problem-solving, and future-ready skills.
          </p>
         
        </div>
      </div>

      <div class="col-lg-8 d-flex align-items-stretch">
        <div class="d-flex flex-column justify-content-center">
          <div class="row gy-4">
            <div class="col-xl-4 d-flex align-items-stretch">
              <div class="icon-box" data-aos="zoom-out" data-aos-delay="300">
                <i class="bi bi-gear"></i>
                <h4>Hands-On Learning</h4>
                <p>Students get to build and program robots and AI systems, gaining practical experience in cutting-edge technologies.</p>
              </div>
            </div>
            
            <div class="col-xl-4 d-flex align-items-stretch">
              <div class="icon-box" data-aos="zoom-out" data-aos-delay="400">
                <i class="bi bi-code-slash"></i>
                <h4>Integrated Coding Skills</h4>
                <p>Our curriculum teaches coding fundamentals, empowering students to create innovative AI and robotics solutions.</p>
              </div>
            </div>
            
            <div class="col-xl-4 d-flex align-items-stretch">
              <div class="icon-box" data-aos="zoom-out" data-aos-delay="500">
                <i class="bi bi-lightbulb"></i>
                <h4>Future-Ready Skills</h4>
                <p>Our programs equip students with the critical skills required for careers in technology, AI, robotics, and beyond.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>




<section id="about" class="about section m-8">
  <div class="container p-4 mt-4" data-aos="fade-up" data-aos-delay="100">
    <div class="row gy-4 align-items-center justify-content-between">
      <div class="col-xl-5 mt-4" data-aos="fade-up" data-aos-delay="200">
        <span class="about-meta">ABOUT US</span>
        <h2 class="about-title">Revolutionizing Education with AI & Robotics</h2>
        <p class="about-description">
          ADVISIONS LAB offers an advanced online learning platform that allows students to dive into AI, Robotics, and other STEM fields from the comfort of their homes. Our curriculum is aligned with NEP 2020 and prepares K-12 students to excel in the tech-driven world of tomorrow.
        </p>
        
        <div class="row feature-list-wrapper">
          <div class="col-md-6">
            <ul class="feature-list">
              <li><i class="bi bi-check-circle-fill"></i> Build Smart Robots with AI</li>
              <li><i class="bi bi-check-circle-fill"></i> Create Real-Time AI Systems</li>
              <li><i class="bi bi-check-circle-fill"></i> Program Autonomous Robots</li>
            </ul>
          </div>
          
          <div class="col-md-6">
            <ul class="feature-list">
              <li><i class="bi bi-check-circle-fill"></i> Learn AI Algorithms and Models</li>
              <li><i class="bi bi-check-circle-fill"></i> Build IoT Solutions and Smart Devices</li>
            </ul>
          </div>
        </div>
        
        <div class="info-wrapper">
          <div class="row gy-4">
            <div class="col-lg-7">
              <div class="contact-info d-flex align-items-center gap-2">
                <i class="bi bi-telephone-fill"></i>
                <div>
                  <p class="contact-label">Contact Us</p>
                  <p class="contact-number">+91-8810316395</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div class="col-xl-6" data-aos="fade-up" data-aos-delay="300">
        <div class="image-wrapper">
          <div class="images position-relative" data-aos="zoom-out" data-aos-delay="400">
            <img src="https://lamarr-institute.org/wp-content/uploads/KNN-OpenRoberta-Quelle-insta-photos-stock-adobe-comFraunhofer-IAIS-1024x683.jpg" alt="Children Building Projects" class="img-fluid main-image rounded-4"/>
            <img src="https://www.oklahoman.com/gcdn/presto/2021/03/01/PSIF/0c70faea-8867-4762-b21c-e636bca23a7b-GettyImages-1216824129_1.jpg?crop=2120,1193,x0,y0" alt="Students Collaborating" class="img-fluid small-image rounded-4"/>
          </div>
          <div class="experience-badge floating">
            <h3>10+ <span>Years</span></h3>
            <p className="text-white">Experience in transforming education</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
  
  <section class="py-5 py-xl-8">
  <div class="container">
    <div class="row justify-content-md-center">
      <div class="col-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
        <h2 class="mb-4 display-5 text-center">Offerings to Make Your School Ready for the 21st Century</h2>
        <p class="text-secondary m-4 mb-5 text-center">We offer a comprehensive range of services to equip schools with cutting-edge technologies. Our focus on STEM, Robotics, IoT, and Digital Tools ensures that your institution stays ahead in preparing students for the future.</p>
        <hr class="w-50 mx-auto mb-5 mb-xl-9 border-dark-subtle"/>
      </div>
    </div>
  </div>
  
  
    <div class="container overflow-hidden">
      <div class="row gy-5 gx-md-5 justify-content-center">
      <div class="col-10 col-md-5 col-xl-4 overflow-hidden">
      <div class="row gy-4">
          <div class="col-12 col-lg-2">
              <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" fill="currentColor" class="bi bi-gear text-primary" viewBox="0 0 16 16">
                  <path d="M9.293 2.293a1 1 0 0 1 1.414 0l1 1a1 1 0 0 1 0 1.414l-1 1a1 1 0 0 1-1.414-1.414L9.293 3.707A1 1 0 0 1 9.707 2.293zM6 6a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h3zm7 3a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-3a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h3z" />
              </svg>
          </div>
          <div class="col-12 col-lg-10">
              <h4 class="mb-3">STEM Education</h4>
              <p class="mb-3 text-secondary">We provide the resources and tools necessary for schools to build strong STEM programs. Equip your students with the skills needed to thrive in the fields of Science, Technology, Engineering, and Mathematics.</p>
              <div>
                  <a href="/stemRoboDetails" class="fw-bold text-decoration-none link-primary">
                      Learn More
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
                          <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
                      </svg>
                  </a>
              </div>
          </div>
      </div>
  </div>
  <div class="col-10 col-md-5 col-xl-4 overflow-hidden">
      <div class="row gy-4">
          <div class="col-12 col-lg-2">
              <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" fill="currentColor" class="bi bi-cogs text-primary" viewBox="0 0 16 16">
                  <path d="M9.293 2.293a1 1 0 0 1 1.414 0l1 1a1 1 0 0 1 0 1.414l-1 1a1 1 0 0 1-1.414-1.414L9.293 3.707A1 1 0 0 1 9.707 2.293zM6 6a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h3zm7 3a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-3a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h3z" />
              </svg>
          </div>
          <div class="col-12 col-lg-10">
              <h4 class="mb-3">Robotics and Automation</h4>
              <p class="mb-3 text-secondary">Our robotics labs offer hands-on experiences for students to learn and experiment with automation, robotics, and AI. Prepare students to engage in the future of technology and engineering.</p>
              <div>
                  <a href="/roboticautomation" class="fw-bold text-decoration-none link-primary">
                      Learn More
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
                          <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
                      </svg>
                  </a>
              </div>
          </div>
      </div>
  </div>
  <div class="col-10 col-md-5 col-xl-4 overflow-hidden">
      <div class="row gy-4">
          <div class="col-12 col-lg-2">
              <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" fill="currentColor" class="bi bi-pc-display text-primary" viewBox="0 0 16 16">
                  <path d="M11 0a1 1 0 0 1 1 1v11a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V1a1 1 0 0 1 1-1h7zm0 1H5v9h6V1z" />
              </svg>
          </div>
          <div class="col-12 col-lg-10">
              <h4 class="mb-3">AI & Machine Learning</h4>
              <p class="mb-3 text-secondary">Equip your students with the knowledge and skills needed to develop AI-based solutions. Our programs focus on machine learning, deep learning, and practical AI application across various industries.</p>
              <div>
                  <a href="/ailab" class="fw-bold text-decoration-none link-primary">
                      Learn More
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
                          <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
                      </svg>
                  </a>
              </div>
          </div>
      </div>
  </div>
  <div class="col-10 col-md-5 col-xl-4 overflow-hidden">
      <div class="row gy-4">
          <div class="col-12 col-lg-2">
              <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" fill="currentColor" class="bi bi-broadcast text-primary" viewBox="0 0 16 16">
                  <path d="M8 0a8 8 0 1 0 0 16 8 8 0 0 0 0-16zm0 14a6 6 0 1 1 0-12 6 6 0 0 1 0 12z" />
                  <path d="M6.293 6.293a1 1 0 1 1 1.414 1.414 4 4 0 0 0 5.656 5.656 1 1 0 0 1 1.414-1.414 6 6 0 0 1-8.484-8.484z" />
              </svg>
          </div>
          <div class="col-12 col-lg-10">
              <h4 class="mb-3">IoT Solutions</h4>
              <p class="mb-3 text-secondary">We provide IoT solutions that connect devices, sensors, and data to create smarter classrooms and campuses. Learn how IoT can improve efficiency, security, and student experience in education.</p>
              <div>
                  <a href="/iotSolutions" class="fw-bold text-decoration-none link-primary">
                      Learn More
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
                          <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
                      </svg>
                  </a>
              </div>
          </div>
      </div>
  </div>
  
  <div class="col-10 col-md-5 col-xl-4 overflow-hidden">
      <div class="row gy-4">
          <div class="col-12 col-lg-2">
              <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" fill="currentColor" class="bi bi-pen text-primary" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M12.854 0.146a1 1 0 0 1 1.414 1.414l-9 9a1 1 0 0 1-.474.263L3.5 9.27a1 1 0 0 1-.263-.474L9.708 1.5l-.708-.708zM2.5 11.146a1 1 0 0 1 0 1.414l3 3a1 1 0 0 1 1.414 0l7-7a1 1 0 0 1 0-1.414l-3-3a1 1 0 0 1-1.414 0l-7 7a1 1 0 0 1-1.414 0l-3-3a1 1 0 0 1 0-1.414l7 7a1 1 0 0 1 0 1.414z" />
              </svg>
          </div>
          <div class="col-12 col-lg-10">
              <h4 class="mb-3">21st Century Showcase School</h4>
              <p class="mb-3 text-secondary">Our Showcase School program is designed to promote 21st-century skills, including critical thinking, creativity, and collaboration, through innovative learning experiences.</p>
              <div>
                  <a href="/showcaseSchool" class="fw-bold text-decoration-none link-primary">
                      Learn More
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
                          <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
                      </svg>
                  </a>
              </div>
          </div>
      </div>
  </div>
  
  <div class="col-10 col-md-5 col-xl-4 overflow-hidden">
      <div class="row gy-4">
          <div class="col-12 col-lg-2">
              <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" fill="currentColor" class="bi bi-flask text-primary" viewBox="0 0 16 16">
                  <path d="M8 0C7.447 0 7 0.447 7 1V7H3V5a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v2h-4V1C9 0.447 8.553 0 8 0zM7 7h2V3H7v4zM7.75 15.25a.25.25 0 0 1-.5 0v-1h2v1a.25.25 0 0 1-.5 0h-1a.25.25 0 0 1-.25-.25h2.5a.25.25 0 0 1-.25.25h-1zm1.5-1v-1h2v1a.25.25 0 0 1-.25.25h-1.5a.25.25 0 0 1-.25-.25zM8 15.5V12a2.5 2.5 0 0 0-2.5 2.5 2.5 2.5 0 0 0 2.5 2.5zm2.5-5.5a1 1 0 0 0-1 1V6a.5.5 0 0 0 .5-.5h1V4h1.5c.4 0 .5.3.5.5v1H8.5V8H7V7.5h1.5z"/>
              </svg>
          </div>
          <div class="col-12 col-lg-10">
              <h4 class="mb-3">Pre-Tinkering Lab</h4>
              <p class="mb-3 text-secondary">The Pre-Tinkering Lab is designed for younger students to explore hands-on STEM concepts and develop problem-solving skills using creative tools and projects.</p>
              <div>
                  <a href="/pretinkeringlab" class="fw-bold text-decoration-none link-primary">
                      Learn More
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
                          <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
                      </svg>
                  </a>
              </div>
          </div>
      </div>
  </div>
  
    
      </div>
    </div>
  </section> 
  
  <div className="container mt-5">
  
    <h3 className="text-primary mb-4 text-center">
      KITS WITH INTEGRATED CURRICULUM FOR AI & IoT LAB
    </h3>
  
    <div className="row">
  
      <div className="col-12 col-sm-6 col-md-3 mb-4">
        <img
          src="https://www.stemrobo.com/wp-content/uploads/2023/08/robotic-kit.jpg"
          alt="AI & IoT 1"
          className="img-fluid rounded shadow"
        />
      </div>
  
      <div className="col-12 col-sm-6 col-md-3 mb-4">
        <img
          src="https://www.stemrobo.com/wp-content/uploads/2023/08/stem-paper-circuit.jpg"
          alt="AI & IoT 2"
          className="img-fluid rounded shadow"
        />
      </div>
  
      <div className="col-12 col-sm-6 col-md-3 mb-4">
        <img
          src="https://www.stemrobo.com/wp-content/uploads/2023/08/Aurdino-robotic-kit.jpg"
          alt="AI & IoT 3"
          className="img-fluid rounded shadow"
        />
      </div>
  
      <div className="col-12 col-sm-6 col-md-3 mb-4">
        <img
          src="https://www.stemrobo.com/wp-content/uploads/2023/08/stembot.jpg"
          alt="AI & IoT 4"
          className="img-fluid rounded shadow"
        />
      </div>
    </div>
  </div>

  
  <section class="bg-light py-5 py-xl-8">
  <div class="container">
    <div class="row justify-content-md-center">
      <div class="col-12 col-md-10 col-lg-8 col-xl-7">
        <h3 class="fs-6 mb-2 text-secondary text-center text-uppercase">Our Expertise</h3>
        <h2 class="display-5 mb-5 text-center">We excel in providing top-notch skills for your success.</h2>
        <hr class="w-50 mx-auto mb-5 mb-xl-9 border-dark-subtle"/>
      </div>
    </div>
  </div>

  <div class="container overflow-hidden">
    <div class="row gy-4 gy-xl-0">
  
      <div class="col-12 col-sm-6 col-xl-3">
        <div class="card border-0 border-bottom border-primary shadow-sm">
          <div class="card-body text-center p-4 p-xxl-5">
            <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" fill="currentColor" class="bi bi-code text-primary mb-4" viewBox="0 0 16 16">
              <path d="M5.719 3.858a.5.5 0 0 0-.638-.058L.58 7.42a.5.5 0 0 0 0 .758l4.5 3.5a.5.5 0 1 0 .638-.766L1.545 8 5.719 4.624a.5.5 0 0 0 .058-.766ZM10.281 3.858a.5.5 0 0 1 .638-.058l4.5 3.5a.5.5 0 0 1 0 .758l-4.5 3.5a.5.5 0 1 1-.638-.766L14.455 8 10.281 4.624a.5.5 0 0 1-.058-.766Z" />
              <path d="M6.854 11.146a.5.5 0 0 1 0 .708l-1 1a.5.5 0 0 1-.708-.708l1-1a.5.5 0 0 1 .708 0ZM9.146 4.854a.5.5 0 0 1 0-.708l1-1a.5.5 0 1 1 .708.708l-1 1a.5.5 0 0 1-.708 0Z" />
            </svg>
            <h4 class="mb-4">Programming</h4>
            <p class="mb-4 text-secondary">Expertise in modern programming languages like JavaScript, Python, and Java to build innovative solutions.</p>
          </div>
        </div>
      </div>

      <div class="col-12 col-sm-6 col-xl-3">
        <div class="card border-0 border-bottom border-primary shadow-sm">
          <div class="card-body text-center p-4 p-xxl-5">
            <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" fill="currentColor" class="bi bi-bar-chart text-primary mb-4" viewBox="0 0 16 16">
              <path d="M0 0h1v15h15v1H0V0Zm10 5h2v8h-2V5ZM6 8h2v5H6V8ZM2 11h2v2H2v-2Z" />
            </svg>
            <h4 class="mb-4">Critical Thinking</h4>
            <p class="mb-4 text-secondary">Develop expertise in tackling multifaceted difficult problems with structured IoT-based solutions.</p>
          </div>
        </div>
      </div>

      <div class="col-12 col-sm-6 col-xl-3">
        <div class="card border-0 border-bottom border-primary shadow-sm">
          <div class="card-body text-center p-4 p-xxl-5">
            <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" fill="currentColor" class="bi bi-brush text-primary mb-4" viewBox="0 0 16 16">
              <path d="M15.825.14c-.646-.646-2.313.38-4.1 2.168-.272.271-.516.539-.733.802-1.62-1.273-3.553-1.504-4.91-.148-1.19 1.19-.97 3.219.242 4.968L.563 12.512a.25.25 0 0 0-.045.253c.302.756.548 1.374.677 1.673.143.33.347.56.58.663.244.107.521.101.92-.013.308-.09.654-.229 1.024-.403a.255.255 0 0 0 .09-.378L7.392 7.6c1.75 1.21 3.777 1.432 4.969.241 1.356-1.356 1.125-3.289-.148-4.91.263-.217.53-.46.802-.732 1.787-1.788 2.813-3.454 2.168-4.1ZM2.507 13.934c-.244.084-.468.152-.664.204.109-.196.2-.42.285-.657l.037-.1c.049-.133.097-.265.145-.388l.197.197c-.46.248-.592.324-.637.344ZM1.05 15h-.03.03Zm.005-.001.016.001H1.05Zm.022.002h-.023.023Z" />
            </svg>
            <h4 class="mb-4">Creative Design</h4>
            <p class="mb-4 text-secondary">Delivering stunning and user-centric designs for web, mobile, and print media that leave a lasting impression.</p>
          </div>
        </div>
      </div>
    
      <div class="col-12 col-sm-6 col-xl-3">
        <div class="card border-0 border-bottom border-primary shadow-sm">
          <div class="card-body text-center p-4 p-xxl-5">
            <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" fill="currentColor" class="bi bi-shield-lock text-primary mb-4" viewBox="0 0 16 16">
              <path d="M5.072 11.293a.5.5 0 0 0 .708-.707 3 3 0 1 1 4.24 0 .5.5 0 1 0 .708.707 4 4 0 1 0-5.656 0ZM8 4a2 2 0 0 1 1.716 3.008A2.99 2.99 0 0 1 8 6a2.99 2.99 0 0 1-1.716.992A2 2 0 0 1 8 4Z" />
              <path d="M8 0c-.69 0-1.382.04-2.073.115C4.224.26 3.443.525 2.854.9a4.001 4.001 0 0 0-1.878 3.053C.497 5.98 0 8.013 0 10.124c0 4.124 4.167 5.907 8 5.907s8-1.783 8-5.907c0-2.11-.497-4.144-1.975-6.171A4.001 4.001 0 0 0 13.146.9c-.59-.375-1.37-.64-2.073-.785A25.362 25.362 0 0 0 8 0Zm.52 11.743a3.522 3.522 0 0 1-1.04 0 3.496 3.496 0 0 1-.52-6.925V2.757a5.977 5.977 0 0 1 1.56 0v2.061a3.497 3.497 0 0 1-.52 6.925Z" />
            </svg>
            <h4 class="mb-4">Cybersecurity</h4>
            <p class="mb-4 text-secondary">Ensuring secure systems by implementing best practices in cybersecurity and vulnerability management.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
  
  
      <section id="call-to-action-2" className="call-to-action-2 section dark-background">
    <div className="container">
      <div className="row justify-content-center" data-aos="zoom-in" data-aos-delay="100">
        <div className="col-xl-10">
          <div className="text-center">
            <h3>Empower Young Minds with KalamLab</h3>
            <p>
              Join the movement to foster innovation and creativity in the next generation. 
              KalamLab equips students with essential skills in robotics, AI, and STEM fields, 
              preparing them for a bright future in technology and beyond.
            </p>
            <a className="cta-btn" href="/roboticsDetails">
              Our Products
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>


  <section class="bg-light py-3 py-md-5">
  <div class="container">
    <div class="row gy-5 gy-lg-0 align-items-lg-center">
      <div class="col-12 col-lg-6">
        <img class="img-fluid rounded" loading="lazy" src="https://bootstrapbrain.com/demo/components/faqs/faq-1/assets/img/faq-img-1.png" alt="How can we help you?"/>
      </div>
      <div class="col-12 col-lg-6">
        <div class="row justify-content-xl-end">
          <div class="col-12 col-xl-11">
            <h2 class="h1 mb-3">How can we help you?</h2>
            <p class="lead fs-4 text-secondary mb-5">We hope you have found an answer to your question. If you need any help, please search your query on our Support Center or contact us via email.</p>
            <div class="accordion accordion-flush" id="accordionExample">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingOne">
                  <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                    How Do I Enroll in the Virtual School?
                  </button>
                </h2>
                <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                  <div class="accordion-body px-4">
                    <p>To enroll in our virtual school, follow these steps:</p>
                    <ul class="no-bullets">
                      <li>Visit our website and click on the "Enroll Now" button.</li>
                      <li>Create an account by entering your details.</li>
                      <li>Select your grade level or preferred course.</li>
                      <li>Complete the payment process if required.</li>
                      <li>You'll receive an email with your login credentials and further instructions.</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingTwo">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                    What Technology is Required to Attend Classes?
                  </button>
                </h2>
                <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                  <div class="accordion-body px-4">
                    <p>To attend classes in our virtual school, you will need:</p>
                    <ul class="no-bullets">
                      <li>A computer, tablet, or smartphone with a stable internet connection.</li>
                      <li>A webcam and microphone for live class participation.</li>
                      <li>The latest version of a modern browser like Chrome, Firefox, or Edge.</li>
                      <li>Access to platforms such as Jitsi Meet for live classes.</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingThree">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                    How Can I Access Recorded Lectures?
                  </button>
                </h2>
                <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                  <div class="accordion-body px-4">
                    <p>Recorded lectures can be accessed by following these steps:</p>
                    <ul class="no-bullets">
                      <li>Log in to your virtual school account.</li>
                      <li>Navigate to the "My Classes" section.</li>
                      <li>Select the class for which you want to view recordings.</li>
                      <li>Click on the "Recorded Lectures" tab to watch the desired session.</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingFour">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                    How Do I Contact My Teachers?
                  </button>
                </h2>
                <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                  <div class="accordion-body px-4">
                    <p>You can contact your teachers by:</p>
                    <ul class="no-bullets">
                      <li>Using the messaging feature on the virtual school platform.</li>
                      <li>Posting questions in the class discussion forum.</li>
                      <li>Joining live sessions during designated office hours.</li>
                      <li>Emailing your teachers using the contact information provided in the platform.</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingFive">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                    How is My Progress Assessed?
                  </button>
                </h2>
                <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                  <div class="accordion-body px-4">
                    <p>We assess your progress through:</p>
                    <ul class="no-bullets mb-4">
                      <li>Regular quizzes and tests conducted online.</li>
                      <li>Assignments submitted through the platform.</li>
                      <li>Participation in class discussions and activities.</li>
                      <li>Final grades based on cumulative performance in all assessments.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
  
    </main>
  
    <Footer/>
  </div>
    )
}
export default LearnMoreStudents;
