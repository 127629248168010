import React, { useState, useEffect } from "react";
import axios from "axios";
import SideBar from "./SideBar";

function Calender() {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [batches, setBatches] = useState([]);
  const [viewMode, setViewMode] = useState("weekly"); // "weekly" or "monthly"
  const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_API_URL });

  // Fetch batch data
  useEffect(() => {
    const fetchBatches = async () => {
      try {
        const response = await axiosInstance.get("/batch/details");
        setBatches(response.data);
      } catch (error) {
        console.error("Error fetching batches:", error);
      }
    };

    fetchBatches();
  }, []);

  const getStartOfWeek = (date) => {
    const start = new Date(date);
    start.setDate(start.getDate() - start.getDay());
    return start;
  };

  const startOfWeek = getStartOfWeek(currentDate);

  const weekDates = Array.from({ length: 7 }).map((_, index) => {
    const date = new Date(startOfWeek);
    date.setDate(startOfWeek.getDate() + index);
    return date;
  });

  const formatDate = (date) =>
    date.toLocaleDateString("en-US", { weekday: "short", day: "numeric" });

  const handlePrevious = () => {
    setCurrentDate((prev) => {
      const newDate = new Date(prev);
      if (viewMode === "weekly") {
        newDate.setDate(newDate.getDate() - 7);
      } else {
        newDate.setMonth(newDate.getMonth() - 1);
      }
      return newDate;
    });
  };

  const handleNext = () => {
    setCurrentDate((prev) => {
      const newDate = new Date(prev);
      if (viewMode === "weekly") {
        newDate.setDate(newDate.getDate() + 7);
      } else {
        newDate.setMonth(newDate.getMonth() + 1);
      }
      return newDate;
    });
  };

  const getBatchesForDate = (date) => {
    return batches.filter((batch) => {
      const batchDate = new Date(batch.date);
      return batchDate.toDateString() === date.toDateString();
    });
  };

  const generateMonthDays = () => {
    const startOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    const endOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

    const monthDates = [];
    let date = new Date(startOfMonth);

    while (date <= endOfMonth) {
      monthDates.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }

    return monthDates;
  };

  const renderWeeklyCalendar = () => (
    <div className="calendar-grid px-4">
      {weekDates.map((date, index) => (
        <div key={index} className="calendar-day">
          <div className="day-header">{formatDate(date)}</div>
          <div className="day-content">
            {getBatchesForDate(date).length > 0 ? (
              getBatchesForDate(date).map((batch, i) => (
                <div key={i} className="batch-info">
                  <p><strong>Course:</strong> {batch.course}</p>
                  <p><strong>Time:</strong> {new Date(batch.date).toLocaleTimeString("en-US", {
                    hour: "2-digit",
                    minute: "2-digit",
                  })}</p>
                </div>
              ))
            ) : (
              <p>No events</p>
            )}
          </div>
        </div>
      ))}
    </div>
  );

  const renderMonthlyCalendar = () => {
    const monthDays = generateMonthDays();

    return (
      <div className="calendar-grid px-4 month-view">
        {monthDays.map((date, index) => (
          <div key={index} className="calendar-day">
            <div className="day-header">{date.getDate()}</div>
            <div className="day-content">
              {getBatchesForDate(date).length > 0 ? (
                getBatchesForDate(date).map((batch, i) => (
                  <div key={i} className="batch-info">
                    <p><strong>Course:</strong> {batch.course}</p>
                    <p><strong>Time:</strong> {new Date(batch.date).toLocaleTimeString("en-US", {
                      hour: "2-digit",
                      minute: "2-digit",
                    })}</p>
                  </div>
                ))
              ) : (
                <p>No events</p>
              )}
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div>
      <SideBar />
      <div className="main-content">
        <div className="card-grid">
          <div className="calendar-header px-4 d-flex align-items-center justify-content-between">
            <button onClick={handlePrevious}>&lt;</button>
            <div className="text-center mt-4">
            <h2>
      {formatDate(weekDates[0])} - {formatDate(weekDates[6])}
    </h2>
    <h3>
      {currentDate.toLocaleDateString("en-US", {
        month: "long",
        year: "numeric",
      })}
    </h3>
            </div>
            <button onClick={handleNext}>&gt;</button>
          </div>

          <div className="view-toggle text-center">
         <div className="d-flex justify-content-between align-items-center">
  <button 
    className="btn btn-primary btn-sm rounded-pill px-4 py-2 ms-auto" 
    onClick={() => setViewMode(viewMode === "weekly" ? "monthly" : "weekly")}
  >
    Switch to {viewMode === "weekly" ? "Monthly View" : "Weekly View"}
  </button>
</div>

          </div>

          {viewMode === "weekly" ? renderWeeklyCalendar() : renderMonthlyCalendar()}
        </div>
      </div>
    </div>
  );
}

export default Calender;
