import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button } from '@mui/material';
import axios from 'axios';

const EditCourseDetailsModal = ({ open, onClose, courseId, onSave }) => {
    const [currentLecture, setCurrentLecture] = useState({
        title: '',
        classSchedule: '',
        overview: '',
        classes: [], // Include an array for classes
    });
    

    const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_API_URL });

    // Fetch course details when the modal opens
    useEffect(() => {
        const fetchCourseDetails = async () => {
            if (courseId && open) {
                try {
                    const response = await axiosInstance.get(`/${courseId}`);
                    console.log(response.data);
                    setCurrentLecture({
                        title: response.data.title || '',
                        classSchedule: response.data.classSchedule || '',
                        overview: response.data.overview || '',
                        classes: response.data.classes || [], // Populate classes
                    });
                } catch (error) {
                    console.error('Error fetching course details:', error);
                }
            }
        };
        

        if (open) {
            fetchCourseDetails();
        }
    }, [courseId, open]);


    const handleChange = (e) => {
        const { name, value } = e.target; // Get the input field's name and value
        setCurrentLecture((prevLecture) => ({
            ...prevLecture,
            [name]: value, // Update the specific property
        }));
    };
    

    const handleClassChange = (index, field, value) => {
        setCurrentLecture((prevLecture) => {
            const updatedClasses = [...prevLecture.classes];
            updatedClasses[index] = {
                ...updatedClasses[index],
                [field]: value,
            };
            return { ...prevLecture, classes: updatedClasses };
        });
    };
    
    const addClass = () => {
        setCurrentLecture((prevLecture) => ({
            ...prevLecture,
            classes: [
                ...prevLecture.classes,
                { title: '', objective: '', topics: [], activity: '', code: '' },
            ],
        }));
    };
    
    const removeClass = (index) => {
        setCurrentLecture((prevLecture) => ({
            ...prevLecture,
            classes: prevLecture.classes.filter((_, i) => i !== index),
        }));
    };
    

    const handleSave = async () => {
        try {
            const formData = {
                title: currentLecture.title,
                classSchedule: currentLecture.classSchedule,
                overview: currentLecture.overview,
                classes: currentLecture.classes, // Include classes
            };
    
            const response = await axiosInstance.put(`/${courseId}/details`, formData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
    
            onSave(response.data); // Notify parent component
            onClose(); // Close the modal
        } catch (error) {
            console.error('Error saving course details:', error);
        }
    };
    

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
            <DialogTitle>{currentLecture.title ? 'Edit Details' : 'Add Details'}</DialogTitle>
            <DialogContent>
    <TextField
        name="title"
        label="Title"
        fullWidth
        margin="dense"
           type="text"
        value={currentLecture.title}
        onChange={handleChange}
    />
    <TextField
        name="classSchedule"
        label="Class Schedule"
        fullWidth
        margin="dense"
           type="text"
        value={currentLecture.classSchedule}
        onChange={handleChange}
    />
    <TextField
        name="overview"
        label="Overview"
        fullWidth
        margin="dense"
           type="text"
        multiline
        rows={4}
        value={currentLecture.overview}
        onChange={handleChange}
    />
    <h4>Classes</h4>
    {currentLecture.classes.map((cls, index) => (
        <div key={index} style={{ marginBottom: '1rem', borderBottom: '1px solid #ccc', paddingBottom: '1rem' }}>
            <TextField
                label="Class Title"
                value={cls.title}
                fullWidth
                margin="dense"
                onChange={(e) => handleClassChange(index, 'title', e.target.value)}
            />
            <TextField
                label="Objective"
                value={cls.objective}
                fullWidth
                margin="dense"
                onChange={(e) => handleClassChange(index, 'objective', e.target.value)}
            />
            <TextField
                label="Topics (Comma-separated)"
                value={cls.topics.join(', ')}
                fullWidth
                margin="dense"
                onChange={(e) => handleClassChange(index, 'topics', e.target.value.split(',').map((t) => t.trim()))}
            />
            <TextField
                label="Activity"
                value={cls.activity}
                fullWidth
                margin="dense"
                onChange={(e) => handleClassChange(index, 'activity', e.target.value)}
            />
            <TextField
                label="Code"
                value={cls.code}
                fullWidth
                margin="dense"
                onChange={(e) => handleClassChange(index, 'code', e.target.value)}
            />
            <Button color="error" onClick={() => removeClass(index)}>
                Remove Class
            </Button>
        </div>
    ))}
    <Button variant="contained" onClick={addClass}>
        Add Class
    </Button>
</DialogContent>

            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={handleSave} color="primary">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EditCourseDetailsModal;
