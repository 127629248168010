import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Sidebar from '../SideBar';
import { TextField, MenuItem, FormControl, Select, InputLabel, Button, Grid, Typography } from '@mui/material';

const BatchAllotmentForm = () => {
  const [courses, setCourses] = useState([]);
  const [classes, setClasses] = useState(['5', '6', '7', '8', '9', '10', '11', '12']);
  const [selectedCourse, setSelectedCourse] = useState('');
  const [selectedClass, setSelectedClass] = useState('');
  const [selectedDate, setSelectedDate] = useState('');

  const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_API_URL });

  useEffect(() => {
    let componentMounted = true;

    const getCourses = async () => {
      try {
        const response = await axiosInstance.post('/data-courses');
        if (componentMounted) {
          setCourses(response.data.data); // Assuming the data is in `data` key
        }
      } catch (error) {
        console.error('Error fetching courses:', error);
      }
    };

    getCourses();
    return () => {
      componentMounted = false;
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      course: selectedCourse,
      class: selectedClass,
      date: selectedDate
    };

    try {
      await axiosInstance.post('/batch/', data);
      alert("Batch allotted successfully");
    } catch (error) {
      console.error("Error allotting batch:", error);
      alert("Failed to allot batch");
    }
  };

  return (
    <div className="app-container app-theme-white body-tabs-shadow fixed-sidebar fixed-header" id="appContent">
      <div className="app-main">
        <Sidebar />
        <div className="col mt-4">
          <div className="row">
            <div className="page-title-actions px-3 d-flex">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><a href="">Dashboard</a></li>
                  <li className="breadcrumb-item"><a href="">Meta Data</a></li>
                  <li className="breadcrumb-item active" aria-current="page">Create</li>
                </ol>
              </nav>
            </div>
            <div className="row" id="deleteTableItem">
              <div className="col-md-12">
                <div className="main-card card d-flex h-100 flex-column">
                  <div className="card-body">
                    <form onSubmit={handleSubmit}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <FormControl fullWidth>
                            <InputLabel id="course-label">Select Course</InputLabel>
                            <Select
                              labelId="course-label"
                              value={selectedCourse}
                              onChange={(e) => setSelectedCourse(e.target.value)}
                              label="Select Course"
                              required
                            >
                              <MenuItem value="">-- Select Course --</MenuItem>
                              {courses.map(course => (
                                <MenuItem key={course._id} value={course.courseName}>{course.courseName}</MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <FormControl fullWidth>
                            <InputLabel id="class-label">Select Class</InputLabel>
                            <Select
                              labelId="class-label"
                              value={selectedClass}
                              onChange={(e) => setSelectedClass(e.target.value)}
                              label="Select Class"
                              required
                            >
                              <MenuItem value="">-- Select Class --</MenuItem>
                              {classes.map(cls => (
                                <MenuItem key={cls} value={cls}>{cls}</MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <TextField
                            type="date"
                            fullWidth
                            label="Select Batch Date"
                            value={selectedDate}
                            onChange={(e) => setSelectedDate(e.target.value)}
                            required
                            InputLabelProps={{ shrink: true }}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <Button variant="contained" color="primary" type="submit" >
                            Allot Batch
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BatchAllotmentForm;
